import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  styled,
  useTheme,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import { data as datos } from "../../resources/data/data";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

interface AddOrUpdateDocument {
  id?: number;
  name: string;
  description: string;
  maxsizes: number;
  extension: string;
}

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

const AddOrUpdateDocument = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {
  const theme = useTheme();
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    description: Yup.string().required("Campo es requerido"),
    maxsizes: Yup.number().required("Campo es requerido"),
    extension: Yup.array(),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    description: data.description ? data.description : "",
    maxsizes: data.maxsizes ? data.maxsizes / 1000000 : 0,
    extension: data.extension ? JSON.parse(data.extension) : [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateDocument>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar Documento" : "Actualizar Documento"}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"maxsizes"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tamaño máximo"
                    type="number"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("maxsizes")}
                    error={errors.maxsizes && Boolean(errors.maxsizes)}
                    helperText={errors.maxsizes && errors.maxsizes.message}
                    InputProps={{
                      inputProps: { min: 1, max: 20 },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="extension"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Extensiones
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("extension")}
                      sx={{
                        "& .MuiSelect-select": { paddingLeft: "0!important" },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.ext.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.ext.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateDocument;
