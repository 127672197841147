import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Divider,
  Dialog,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Stack,
  InputLabel,
  FormLabel,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../../components/svgicons/SvgIcons";
import FancyPaper from "../../components/FancyPaper";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  getRequestBlackList,
  getRequestBlackListID,
  createRequestBlackList,
  updateRequestBlackList,
  deleteRequestBlackListID,
} from "../../services/blacklist/BlackListService";
import { getRubroWithUrl } from "../../services/rubros/RubrosService";
import { useApp } from "../../hooks/useApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AddOrUpdateBlackList from "../../components/utils/AddOrUpdateBlackList";
import DeleteModal from "../../components/utils/DeleteModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import { properties } from "../../utils/Properties_es";
import { PatternFormat } from "react-number-format";

interface searchBlackListTypes {
  alias?: string;
  typeIdentification?: string;
  document?: string;
  isClient?: string;
  name?: string;
  source?: string;
  surname?: string;
}

const defaultValues: searchBlackListTypes = {
  alias: "",
  typeIdentification: "",
  //typeIdentification: "CI",
  document: "",
  isClient: "",
  name: "",
  source: "",
  surname: "",
};

const validation = Yup.object().shape({
  alias: Yup.string(),
  document: Yup.string(),
  isClient: Yup.string(),
  name: Yup.string(),
  source: Yup.string(),
  surname: Yup.string(),
});

const BlackListManagement = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [blackListData, setBlackListData] = useState<any>([]);
  const [countries, setCountries] = useState<any>(null);
  const [preFilter, setPreFilter] = useState<any>(defaultValues);
  const [page, setPage] = useState(0);
  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    reset,
    resetField,
  } = useForm<searchBlackListTypes>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors } = formState;
  const watch_typeIdentification = watch("typeIdentification");

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    let dataInit;
    dataInit = async () => {
      await handleFecthCountry();
      await handleFetchData(0, preFilter);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (watch_typeIdentification) {
      resetField("document", {
        defaultValue: "",
      });
    }
  }, [watch_typeIdentification]);

  const handleFecthCountry = async () => {
    let country: any = await getRubroWithUrl("countries", 238, 0);
    let cleanCountry = await country?.content.map(
      ({ code, impact, inherentRisk, probability, tableName, ...rest }: any) =>
        rest
    );
    if (cleanCountry) {
      setCountries(cleanCountry);
    }
  };

  const handleFetchData = async (currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestBlackList(currentPage, filter);
      if (data) {
        setBlackListData(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleFecthByID = async (id: number) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequestBlackListID(id);
      if (data) {
        return data;
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequestBlackList({
        ...data,
        user: authInfo?.username,
        nationality: data.nationality.id,
        createdAt: new Date(),
        createdBy: authInfo?.username,
        updatedAt: new Date(),
        updatedBy: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg("Error al agregar nueva registro en lista negra");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Nuevo registro agregado en lista negra");
      await handleFetchData(0, "");
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await updateRequestBlackList({
        ...data,
        nationality: data.nationality.id,
        updatedAt: new Date(),
        updatedBy: authInfo?.username,
        user: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg("Error al actualizar datos en lista negra");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Datos actualizados en lista negra");
      await handleFetchData(0, "");
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleDeleteID = async (id: number) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteID = await deleteRequestBlackListID(id, authInfo?.username);
      console.log(deleteID);
      if (!deleteID) {
        setErrorMsg && setErrorMsg("Error al eliminar registro");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Registro eliminado de lista negra");
      setPage(0);
      await handleFetchData(0, "");
    } catch (error: any) {
      console.log(error);
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object = await handleFecthByID(id);
    }

    if (modalAction === "delete") {
      object = blackListData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleClearFilter = async (e: any) => {
    await handleFetchData(0, preFilter);
    reset(defaultValues);
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDeleteID(modalData?.modalObject?.id);
        break;
      default:
        await handleFetchData(0, data);
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Listas negras">
        <Grid container spacing={4} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtrar por:
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nombre"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("name")}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"surname"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Apellido"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("surname")}
                  error={errors.surname && Boolean(errors.surname)}
                  helperText={errors.surname && errors.surname.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3} md={1}>
            <Controller
              name="typeIdentification"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth variant="standard">
                  <Select
                    value={value !== "" ? value : "CI"}
                    {...register("typeIdentification")}
                    sx={{
                      "& .MuiSelect-select": {
                        fontSize: "1rem",
                        padding: "7.765px 0 1.765px!important",
                      },

                      marginTop: "16px",
                    }}
                  >
                    <MenuItem value="CI">Cédula</MenuItem>
                    <MenuItem value="Pasaporte">Pasaporte</MenuItem>
                    <MenuItem value="RNC">RNC</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={2} xs={9}>
            <Controller
              name={"document"}
              control={control}
              render={({ field: { onChange, value } }) =>
                watch_typeIdentification !== "Pasaporte" ? (
                  <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                    <PatternFormat
                      className="input-due"
                      format={
                        watch_typeIdentification === "CI"
                          ? "###-#######-#"
                          : "#-##-#####-#"
                      }
                      mask="_"
                      value={value}
                      onChange={onChange}
                      placeholder={
                        watch_typeIdentification === "CI"
                          ? "###-#######-#"
                          : "#-##-#####-#"
                      }
                    />
                  </FormControl>
                ) : (
                  <TextField
                    label="Identificación"
                    type="text"
                    variant="standard"
                    fullWidth
                    value={value}
                    {...register("document")}
                    error={errors.document && Boolean(errors.document)}
                    helperText={errors.document && errors.document.message}
                  />
                )
              }
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"alias"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Alias"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("alias")}
                  error={errors.alias && Boolean(errors.alias)}
                  helperText={errors.alias && errors.alias.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"isClient"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth className={"disabled-select"}>
                  <InputLabel>¿Es cliente?</InputLabel>
                  <Select
                    variant="standard"
                    value={value}
                    {...register("isClient")}
                  >
                    <MenuItem value="">Seleccione</MenuItem>
                    <MenuItem value="1">Sí</MenuItem>
                    <MenuItem value="2">No</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"source"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Fuente"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("source")}
                  error={errors.source && Boolean(errors.source)}
                  helperText={errors.source && errors.source.message}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <ResourceAccess isCode={true} pathOrCode={"BLACKLIST:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <ResourceAccess isCode={true} pathOrCode={"BLACKLIST:WRITE"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Buscar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClearFilter}
                >
                  Limpiar
                  <PlusIcon sx={{ ml: 1 }} />
                </Button>
              </ResourceAccess>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 8, pb: 8 }}>
          <Grid item xs={12}>
            <Divider />
            <TableContainer component={Paper} sx={{ overflowX: "scroll" }}>
              <Table sx={{ minWidth: 1200 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell align="center">Alias</TableCell>
                    <TableCell align="center">Tipo de cliente</TableCell>
                    <TableCell align="center">Nacionalidad</TableCell>
                    <TableCell align="center" /* sx={{ width: "250px" }} */>
                      Fuente
                    </TableCell>
                    <TableCell align="center">Documento</TableCell>
                    <TableCell align="center">¿Es cliente?</TableCell>
                    <TableCell align="center">Creado</TableCell>
                    <TableCell align="center">Modificado</TableCell>
                    <TableCell align="center">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {blackListData &&
                    blackListData.content &&
                    blackListData.content.map((row: any, i: number) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell>{row.name + " " + row.surname}</TableCell>
                        <TableCell align="center">{row.alias}</TableCell>
                        <TableCell align="center">
                          {row.client_type.toUpperCase() === "F"
                            ? "Físico"
                            : "Jurídico"}
                        </TableCell>
                        <TableCell align="center">
                          {row.nationality
                            ? countries?.find(
                                (d: any) =>
                                  Number(d.id) === Number(row.nationality)
                              )?.country
                            : ""}
                        </TableCell>
                        <TableCell
                          align="center"
                          /* sx={{ width: "250px" }}
                          width={250} */
                        >
                          {row.source}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          {row.document_type + " - " + row.document_number}
                        </TableCell>
                        <TableCell align="center">
                          {row.is_client ? (
                            <CheckCircleIcon sx={{ color: "#1b5e20" }} />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          {row.createdBy}
                          <br />
                          {row.createdAt}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          {row.updatedBy} <br /> {row.updatedAt}
                        </TableCell>
                        <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                          <ResourceAccess
                            isCode={true}
                            pathOrCode={"BLACKLIST:WRITE"}
                          >
                            <FancyTooltip title="Editar" placement="top">
                              <IconButton
                                aria-label="edit"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                onClick={handleOpenModal}
                                data-name="update"
                                data-id={row.id}
                              >
                                <PenIcon />
                              </IconButton>
                            </FancyTooltip>
                            <FancyTooltip title="Eliminar" placement="top">
                              <IconButton
                                aria-label="trash"
                                component="label"
                                color="secondary"
                                sx={{
                                  "&:hover": {
                                    color: theme.palette.secondary.dark,
                                  },
                                }}
                                data-id={row.id}
                                onClick={handleOpenModal}
                                data-name="delete"
                              >
                                <TrashIcon />
                              </IconButton>
                            </FancyTooltip>
                          </ResourceAccess>
                        </TableCell>
                      </TableRow>
                    ))}
                  {(!blackListData ||
                    !blackListData.content ||
                    blackListData.content.length <= 0) && (
                    <TableRow>
                      <TableCell colSpan={11}>
                        {properties.com_parval_label_table_empty}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <FancyTablePagination
              count={
                blackListData?.content?.length > 0
                  ? blackListData?.content?.length
                  : 0
              }
              rowsPerPage={blackListData.size}
              page={page}
              onPageChange={handleChangePage}
              totalElements={blackListData.totalElements}
              totalPages={blackListData.totalPages}
            />
          </Grid>
        </Grid>
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateBlackList
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            country={countries}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.name}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default BlackListManagement;
