import {
  Grid,
  TextField,
  Autocomplete,
  Divider,
} from "@mui/material";
import TitleForm from "../../components/TitleForm";
import { useForm, Controller, useFieldArray } from "react-hook-form";

const AdditionalLegalInformation = ({dataStep2} : any) => {

  const defaultValues: any = {
    main_suppliers_entity: (dataStep2.main_suppliers_entity &&
      JSON.parse(dataStep2.main_suppliers_entity)) ?? [
      {
        social_name: "",
        economic_sector: null,
      },
    ],
    information_shareholders: (dataStep2.information_shareholders &&
      JSON.parse(dataStep2.information_shareholders)) ?? [
      {
        full_name: "",
        identification_number: "",
      },
    ],
    affiliated_companies: (dataStep2.affiliated_companies &&
      JSON.parse(dataStep2.affiliated_companies)) ?? [
      {
        name_or_social_name: "",
        rnc_or_equivalent: "",
      },
    ],
    commercial_references: (dataStep2.commercial_references &&
      JSON.parse(dataStep2.commercial_references)) ?? [
      {
        commercial_phone: "",
        commercial_name: "",
      },
    ],
  };

  const {
    register,
    control,
  } = useForm({
    defaultValues,
  });

  const {
    fields: main_suppliers_entityFields,
  } = useFieldArray({
    control,
    name: "main_suppliers_entity",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: information_shareholdersFields,
  } = useFieldArray({
    control,
    name: "information_shareholders",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: affiliated_companiesFields,
  } = useFieldArray({
    control,
    name: "affiliated_companies",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: commercial_referencesFields,
  } = useFieldArray({
    control,
    name: "commercial_references",
    rules: {
      minLength: 3,
    },
  });


  return (
    <form >
      <TitleForm>Datos de Accionistas, Proveedores y Socios </TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales proveedores
      </TitleForm>
      {main_suppliers_entityFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_suppliers_entity.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `main_suppliers_entity.${index}.social_name` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`main_suppliers_entity.${index}.country_of_origin`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={data && countryASC}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="País de procedencia"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales Accionistas
      </TitleForm>
      {information_shareholdersFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`information_shareholders.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre completo"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `information_shareholders.${index}.full_name` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`information_shareholders.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Documento de identidad"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `information_shareholders.${index}.identification_number` as const
                      )}

                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Empresas afiliadas, subsidiarias y compañias relacionadas
      </TitleForm>
      {affiliated_companiesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`affiliated_companies.${index}.name_or_social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre / razón social"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `affiliated_companies.${index}.name_or_social_name` as const
                      )}

                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`affiliated_companies.${index}.rnc_or_equivalent`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="RNC o equivalente"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `affiliated_companies.${index}.rnc_or_equivalent` as const
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Referencias comerciales
      </TitleForm>
      {commercial_referencesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`commercial_references.${index}.commercial_phone`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `commercial_references.${index}.commercial_phone` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`commercial_references.${index}.commercial_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `commercial_references.${index}.commercial_name` as const
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}

    </form>
  );
};

export default AdditionalLegalInformation;
