import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch.js";
import { CONSTANT } from "../utils/Constants";
import { formatDate } from "../utils/UtilsFunctions";
import Urls from "../url/Urls";

export const getRequest = async (page:number, filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["code", filter.code],
    ["name", filter.name],
    ["identification", filter.identification],
    ["personType", filter.personType ? filter.personType : ''],
    ["fileForm", filter.fileForm ? filter.fileForm : '']
  ]);  
  let keysParams:any = [];
  params.forEach((value, key) => {
    if (value === '' || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key:any) => {
    params.delete(key);
  });  
  await privateFetch
    .get(
      URL.REQUEST_CLIENT_LIST(
        formatDate(filter.endDate),        
        page,
         Urls.registration_x_page,    
        formatDate(filter.startDate)
        
      ),  
      { params }  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      console.log(error)
     
    });
  return responseData;
};

// throw new Error("Error en consulta de clientes");     

export const findByIdRequest = async (id:string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_CLIENT(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en consulta por id de cliente");
    });
  return responseData;
};


export const getStoresClient = async (id:string, page:number) => {
  let responseData = {};   
  await privateFetch
    .get(
      URL.REQUEST_STORES_CLIENT(
        id,
        page,
        "5"        
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de historico por cliente");
    });
  return responseData;
};