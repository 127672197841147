import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Stack,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import Loading from "../components/utils/Loading";
import { getAllInvestmentProfiles } from "../services/investment/InvestmentService";

const LegalRegistry = () => {
  const { isLoading, setLoading, setErrorMsg } = useApp();
  const theme = useTheme();
  const [allInvestments, setAllInvestments] = useState<any>([]);

  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");

  //navigation react
  const navigate = useNavigate();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (page: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getAllInvestmentProfiles(page, 10, filter);
      if (data) {
        // @ts-ignore
        setAllInvestments(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, filter);
    }
  };

  const handleApplyFilter = async () => {
    await handleFetchData(page, filter);
  };

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle="Clientes Jurídicos">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                type="text"
                fullWidth
                value={filter}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                onChange={(e) => {
                  setFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Denominación Social</TableCell>
                <TableCell align="center">Correo Electrónico</TableCell>
                <TableCell align="center">RNC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allInvestments.content &&
                allInvestments.content.map((row: any, i: number) => (
                  <TableRow
                    onClick={() => {
                      navigate(`/legalregistry/${row.userId}`);
                    }}
                    key={i}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="center">
                      {row.name_social_denomination}
                    </TableCell>
                    <TableCell align="center">{row.company_email}</TableCell>
                    <TableCell align="center">
                      {row.rnc_document_number}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            allInvestments?.content?.length > 0
              ? allInvestments?.content.length
              : 0
          }
          rowsPerPage={allInvestments?.size || 10}
          page={page}
          onPageChange={handleChangePage}
          totalElements={allInvestments?.totalItems}
          totalPages={allInvestments?.totalPages}
        />
      </FancyPaper>
    </>
  );
};

export default LegalRegistry;
