import { Button, Card, CardContent, CardHeader, SvgIcon } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Chart } from "react-google-charts";

const PieChartDashboard = (props: any) => {
  const { data, sx, title, titleChart, handleClick } = props;

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            name="customerStage"
            startIcon={
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            }
            onClick={handleClick}
          >
            Sync
          </Button>
        }
        title={title}
      />
      <CardContent>
        <Chart
          chartType="PieChart"
          data={data}
          options={{ title: `${titleChart}` }}
          width={"100%"}
          height={"400px"}
        />
      </CardContent>
    </Card>
  );
};

export default PieChartDashboard;
