import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import Urls from "../../url/Urls";

export const getRequestDocumentRole = async (filter: string|null, treasury:string, description: string, page: number) => {
  let responseData = {};  
  
  await privateFetch
    .get(
      URL.REQUEST_DOCUMENT_ROLE_LIST( filter, treasury, description,
        page,        
        Urls.registration_x_page
        ),
        
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_documentRole_find_error);
    });
  return responseData;
};

export const findByIdRequest = async (id:string) => {
  let responseData = {};  
  await privateFetch
    .get(
      URL.REQUEST_DOCUMENT_ROLE(id)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_documentRole_find_error);
    });
  return responseData;
};

export const createRequest = async (data:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["document.id", data.document.id],
    ["requiredPhysical", data.requiredPhysical],
    ["requiredJuridic", data.requiredJuridic],
    ["signatureType", JSON.stringify(data.signatureType)],
    ["role.id", data.role.id],
    ["personType", JSON.stringify(data.personType)],
    ["risk", JSON.stringify(data.risk)]
  ]);

  console.log(data)
  await privateFetch
    .post(URL.REQUEST_DOCUMENT_ROLE(null),  {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    },  
    { params }  )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_documentRole_save_error);
    });
  return responseData;
};

export const createRequestTreasury = async (data:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["document.id", data.document],
    //["required", data.required],
    ["role.id", data.role],
    ["personType", JSON.stringify(data.personType)],
    ["risk", JSON.stringify(data.risk)],
    ["treasury", data.treasury],
    ["requiredPhysical", false],
    ["requiredJuridic", data.requiredJuridic],
  ]);

  console.log(data)
  await privateFetch
    .post(URL.REQUEST_DOCUMENT_ROLE(null),  {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    },  
    { params }  )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_documentRole_save_error);
    });
  return responseData;
};

export const updateRequest = async (data:any) => {
  let responseData = {};
  const params = new URLSearchParams([ 
    //["id", data.id],
    ["document.id", data.document.id],
    ["role.id", data.role.id],
    ["personType", JSON.stringify(data.personType)],
    ["risk", JSON.stringify(data.risk)],
    ["signatureType", JSON.stringify(data.signatureType)],
    ["requiredPhysical", data.requiredPhysical],
    ["requiredJuridic", data.requiredJuridic],
  ]);  
  console.log(data)
  await privateFetch
    .put(URL.REQUEST_DOCUMENT_ROLE(data.id),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    },  
    { params })  
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_documentRole_update_error);
    });
  return responseData;
};

export const updateRequestTreasury = async (data:any) => {
  let responseData = {};
  const params = new URLSearchParams([ 
    ["document.id", data.document],    
    ["role.id", data.role],
    ["personType", JSON.stringify(data.personType)],
    ["risk", JSON.stringify(data.risk)],
    ["treasury", data.treasury],
    ["signatureType", JSON.stringify(data.signatureType)],
    ["requiredPhysical", false],
    ["requiredJuridic", data.requiredJuridic],
    
  ]);  
  console.log(data)
  await privateFetch
    .put(URL.REQUEST_DOCUMENT_ROLE(data.id),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    },  
    { params })  
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_documentRole_update_error);
    });
  return responseData;
};


export const deleteRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_DOCUMENT_ROLE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_documentRole_delete_error);
    });
  return responseData;
};

