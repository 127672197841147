import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";
import UserManagement from "../pages/UserManagement";
import Reports from "../pages/Reports";
import RoleManagement from "../pages/RoleManagement";
import EmailRoleManagement from "../pages/EmailRoleManagement";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DocumentsRoleManagement from "../pages/DocumentsRoleManagement";
import DocumentsRoleManagementTreasury from "../pages/treasury/DocumentsRoleManagementTreasury";
import DocumentsManagement from "../pages/DocumentsManagement";
import AuthorityManagement from "../pages/AuthorityManagement";
import AuthorityByRoleManagement from "../pages/AuthorityByRoleManagement";
import ClientManagement from "../pages/ClientManagement";
import Dashboard from "../pages/Dashboard";
import RubrosManagement from "../pages/RubrosManagement";
import RubroPage from "../pages/Rubro";
import LegalRegistry from "../pages/LegalRegistry";
import ProfileInvertor from "../pages/ProfileInverstor";
import BlackListManagement from "../pages/blacklist/BlackListManagement";
import NotaryManagement from "../pages/maintenanceInvestment/NotaryManagement";
import BranchOfficeManagements from "../pages/maintenanceInvestment/BranchOfficeManagements";
import BankEntityManagement from "../pages/maintenanceInvestment/BankEntityManagement";

const Session = lazy(() => import("../pages/session/Session"));
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<DashboardHome />}>
        <Route index element={<Dashboard />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/rubrosManagement" element={<RubrosManagement />} />
        <Route path="/rubrosManagement/:endPointName" element={<RubroPage />} />
        <Route path="/clients" element={<ClientManagement />} />
        <Route path="/legalregistry" element={<LegalRegistry />} />
        <Route path="/legalregistry/:id" element={<ProfileInvertor />} />
        <Route path="/roleManagement" element={<RoleManagement />} />
        <Route path="/emailRoleManagement" element={<EmailRoleManagement />} />
        <Route path="/documentsRole" element={<DocumentsRoleManagement />} />
        <Route
          path="/documentsRoleTreasury"
          element={<DocumentsRoleManagementTreasury />}
        />
        <Route path="/documents" element={<DocumentsManagement />} />
        <Route path="/authority" element={<AuthorityManagement />} />
        <Route path="/blacklist" element={<BlackListManagement />} />
        <Route
          path="/authoritiesbyrole/:roleId"
          element={<AuthorityByRoleManagement />}
        />
        <Route path="/notaryManagement" element={<NotaryManagement />} />
        <Route
          path="/branchOfficeManagement"
          element={<BranchOfficeManagements />}
        />
        <Route
          path="/bankEntityManagement"
          element={<BankEntityManagement />}
        />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);
