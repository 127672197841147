import { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Typography,
  Box,
  Divider,
  CircularProgress,
  Button,
  useTheme,
  Autocomplete,
  TextField,
} from "@mui/material";
import TitleForm from "../../components/TitleForm";
import { getCalculate } from "../../services/investment/InvestmentService";
import { privateFetch } from "../../utils/CustomFetch";
import { useForm, Controller } from "react-hook-form";
import { data as datos } from "../../data/data";

const InvestorTypeResults = ({ userId, dataStep5, dataJ }: any) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false);

  const [investmentProfile, setInvestmentProfile] = useState<any>(null);

  const defaultValues: any = {
    agree_cat_investor_profile: dataStep5.agree_cat_investor_profile
      ? dataStep5.agree_cat_investor_profile
      : "",
    /* type_investor_considered: dataStep5.type_investor_considered
      ? dataJ &&
        dataJ.pa_ta_type_investor_considered.find(
          (f: any) => f.id === Number(dataStep5.type_investor_considered)
        )
      : [],
    what_cat_investor_considered: dataStep5.what_cat_investor_considered
      ? dataJ &&
        dataJ.pa_ta_what_cat_investor_considered.find(
          (f: any) => f.id === Number(dataStep5.what_cat_investor_considered)
        )
      : [], */
  };

  const { control } = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const getInvestmentProfile = async () => {
    setLoading(true);
    const response = await getCalculate(userId);
    console.log(response);
    if (response !== null) {
      setInvestmentProfile(response);
    }
    setLoading(false);
  };

  useEffect(
    () => {
      if (userId !== null) {
        getInvestmentProfile();
      }
    },
    // eslint-disable-next-line
    [userId]
  );

  const handleDownloadKYC = async () => {
    setLoadedPDFKYC(true);
    await privateFetch
      .get(`investment-profile/documents/pdf/perfil/${userId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "evaluación-del-perfil-del-inversionista-persona-juridica.pdf"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
      });
    await privateFetch
      .get(`investment-profile/documents/pdf/brokerage-contract/${userId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const urlAnexoI = window.URL.createObjectURL(new Blob([resp.data]));
        const linkAnexoI = document.createElement("a");
        linkAnexoI.href = urlAnexoI;
        linkAnexoI.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa-anexoI.pdf"
        );
        document.body.appendChild(linkAnexoI);
        linkAnexoI.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
      });
    await privateFetch
      .get(`investment-profile/documents/pdf/anexo-2/${userId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const urlAnexoII = window.URL.createObjectURL(new Blob([resp.data]));
        const linkAnexoII = document.createElement("a");
        linkAnexoII.href = urlAnexoII;
        linkAnexoII.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa-anexoII.pdf"
        );
        document.body.appendChild(linkAnexoII);
        linkAnexoII.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
      });
    setLoadedPDFKYC(false);
  };

  const handleDownloadContract = async () => {
    setLoadedPDFKYC(true);
    await privateFetch
      .get(`investment-profile/documents/pdf/contract/${userId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "contrato-de-corretaje-parval-puesto-de-bolsa.pdf"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
      });
    setLoadedPDFKYC(false);
  };

  /*  const handleDownloadMatriz = async () => {
    setLoadedPDFKYC(true);
    await privateFetch
      .get(`investment-profile/documents/pdf/matriz/${userId}`, {
        responseType: "blob",
      })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "conozca-matriz-de-su-cliente.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e: any) => {
        setLoadedPDFKYC(false);
      });
    setLoadedPDFKYC(false);
  }; */

  return (
    <>
      {/* {investmentProfile !== null ? ( */}

      {true !== null ? (
        <div>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 4 }}
            columnSpacing={{ xs: 2, sm: 4 }}
            sx={{
              maxWidth: "950px",
              mx: {
                md: "auto!important",
              },
              pr: {
                md: "32px",
              },
            }}
          >
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <TitleForm sx={{ mt: 4, mb: 4 }}> Resultado Evaluación</TitleForm>
              <Typography
                variant="body1"
                align="center"
                fontSize="1.05rem"
                sx={{ mb: 6 }}
              >
                Según la información suministrada por el cliente acerca de su
                nivel profesional, fuente de fondos, conocimiento de productos
                financieros, apetito de riesgo y rentabilidad esperada, la
                recomendación según su perfil de riesgo se ajusta a:
              </Typography>

              {loading ? (
                <Stack spacing={2} direction="row">
                  <CircularProgress
                    size={24}
                    sx={{
                      margin: "5px auto",
                      color: `${theme.palette.primary.main}`,
                    }}
                  />
                </Stack>
              ) : (
                <>
                  <Typography
                    align="center"
                    variant="h6"
                    color="primary"
                    fontSize="1.8rem"
                  >
                    {
                      //@ts-ignore
                      investmentProfile?.result?.INVESTOR_TYPE
                    }
                  </Typography>
                  {
                    //@ts-ignore
                    investmentProfile?.result?.INVESTOR_CATEGORY && (
                      <>
                        <Divider
                          sx={{
                            pt: 3,
                            mb: 3,
                            borderColor: "secondary.main",
                            width: "50%",
                            maxWidth: "450px",
                            ml: "auto",
                            mr: "auto",
                          }}
                        />

                        <Typography
                          align="center"
                          variant="h5"
                          fontSize="2rem"
                          sx={{
                            width: "100%",
                            maxWidth: "300px",
                            backgroundColor: "primary.main",
                            color: "#fff",
                            pb: "4px",
                            margin: "0 auto",
                            borderRadius: "4px",
                            "&:hover": {
                              backgroundColor: "primary.main",
                              opacity: [0.9, 0.8, 0.9],
                            },
                          }}
                          color="light.contrastText"
                        >
                          {
                            //@ts-ignore
                            investmentProfile?.result?.INVESTOR_CATEGORY
                          }
                        </Typography>
                      </>
                    )
                  }
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 0,
                      borderColor: "secondary.main",
                      width: "50%",
                      maxWidth: "450px",
                      ml: "auto",
                      mr: "auto",
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <TitleForm variant="h6" sx={{ mt: 4, mb: 4 }}>
            Confirmación de Perfil
          </TitleForm>
          <form>
            <Grid container spacing={4} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="agree_cat_investor_profile"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_yes_or_no}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="¿Cliente está de acuerdo con la categorización de su
                            perfil de inversionista?"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <Controller
                  control={control}
                  name="type_investor_considered"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_type_investor_considered}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value
                      }
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="what_cat_investor_considered"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.pa_ta_what_cat_investor_considered}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value
                      }
                      value={value}
                      disabled
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password3";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
                            variant="standard"
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid> */}
            </Grid>
          </form>
          <Grid
            container
            rowSpacing={{ xs: 2, sm: 4 }}
            columnSpacing={{ xs: 2, sm: 4 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="body1"
                align="center"
                fontSize="1.5rem"
                sx={{ mt: "2rem" }}
              >
                Descargar documentos
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                justifyContent="center"
              >
                {loadedPDFKYC ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      marginRight: "5px",
                      color: `${theme.palette.primary.main}`,
                    }}
                  />
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={handleDownloadKYC}
                      disabled={loading}
                    >
                      Perfil del Inversionista, anexo I y anexo II
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={handleDownloadContract}
                      disabled={loading}
                    >
                      Contrato de corretaje
                    </Button>
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={handleDownloadMatriz}
                      disabled={loading}
                    >
                      Matriz de Riesgo
                    </Button> */}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress color="primary" />
          </Stack>
        </>
      )}
    </>
  );
};

export default InvestorTypeResults;
