import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { itemNotary } from "../../types/investmentMaintenanceType";

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: any;
}

const AddOrUpdateNotary = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const validation = Yup.object().shape({
    address: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
    school: Yup.string().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    address: data.address ? data.address : "",
    name: data.name ? data.name : "",
    school: data.school ? data.school : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<itemNotary>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {modalType === "create" ? "Agregar notario" : "Editar notario"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    value={value}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={"school"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nro de colegio de notarios"
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    value={value}
                    {...register("school")}
                    error={errors.school && Boolean(errors.school)}
                    helperText={errors.school && errors.school.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"address"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Dirección"
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    value={value}
                    {...register("address")}
                    error={errors.address && Boolean(errors.address)}
                    helperText={errors.address && errors.address.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateNotary;
