import { Typography } from "@mui/material";

const ShowListPersonType = ({ personType }: any) => {
  const {
    nacional_institucional,
    patrinomio_autonomos,
    nacional_sin_fin_lucro,
    nacional_no_profesional,
    contrapartes,
  } = personType;

  const renderList = (items: any, title: string) => {
    if (items?.length > 0) {
      return (
        <Typography variant="body2">
          <strong>{title}: </strong>
          {items.map((item: string, index: number) => (
            <span key={index}>
              {item}
              {index < items.length - 1 && ", "}
            </span>
          ))}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      {renderList(nacional_institucional, "Nacionales institucionales: ")}
      {renderList(
        patrinomio_autonomos,
        "Patrimonios autónomos (Institucionales): "
      )}
      {renderList(nacional_sin_fin_lucro, "Nacionales sin fines de lucro: ")}
      {renderList(nacional_no_profesional, "Nacionales no profesionales: ")}
      {renderList(contrapartes, "Contrapartes: ")}
    </>
  );
};

export default ShowListPersonType;
