import { useEffect } from "react";
import {
  Button,
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  MenuItem,
  Box,
  Chip,
  ListItemText,
  Select,
  FormControl,
  FormLabel,
  useTheme,
  FormGroup,
  Divider,
  FormHelperText,
  InputLabel,
  IconButton,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { data as datos } from "../../resources/data/data";
import FancyTooltip from "./FancyTooltip";
import { PlusIcon } from "../svgicons/SvgIcons";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateDocumentRole {
  id?: number;
  document?: {
    id?: number;
    name?: string;
  }[];
  role: {
    id?: number;
    name?: string;
  }[];
  nacionales_profesionales?: boolean;
  nacional_institucional?: string;
  patrinomio_autonomos?: string;
  nacional_sin_fin_lucro?: string;
  nacional_no_profesional?: string;
  contrapartes?: string;
  /* personType: {
    legalPerson: string;
    subLegalPerson: string;
  }[]; */
  risk: string;
  requiredJuridic: boolean;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
  documentListData?: any;
}

const AddOrUpdateDocumentRoleTreasury = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  documentListData,
}: DialogProps) => {
  const theme = useTheme();

  const personTypeValidate = {
    legalPerson: Yup.string().required("Campo es requerido"),
    subLegalPerson: Yup.string().required("Campo es requerido"),
  };
  const validation = Yup.object().shape({
    document: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    role: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    nacionales_profesionales: Yup.boolean(),
    nacional_institucional: Yup.array(),
    patrinomio_autonomos: Yup.array(),
    nacional_sin_fin_lucro: Yup.array(),
    nacional_no_profesional: Yup.array(),
    contrapartes: Yup.array(),
    risk: Yup.array(Yup.string().required()).min(1, "Campo es requerido"),
    requiredJuridic: Yup.boolean(),
  });

  const defaultValues = {
    id: data?.id ? data.id : 0,
    document: data?.document
      ? documentListData.content.find((d: any) => d.id === data.document.id)
      : [],
    role: data?.role ? roleData.find((f: any) => f.id === data.role.id) : [],
    requiredJuridic: data?.requiredJuridic ? data.requiredJuridic : false,
    nacionales_profesionales: data?.personType
      ? JSON.parse(data.personType)?.nacionales_profesionales
      : false,
    nacional_institucional: data?.personType
      ? JSON.parse(data.personType)?.nacional_institucional
      : [],
    patrinomio_autonomos: data?.personType
      ? JSON.parse(data.personType)?.patrinomio_autonomos
      : [],
    nacional_sin_fin_lucro: data?.personType
      ? JSON.parse(data.personType)?.nacional_sin_fin_lucro
      : [],
    nacional_no_profesional: data?.personType
      ? JSON.parse(data.personType)?.nacional_no_profesional
      : [],
    contrapartes: data?.personType
      ? JSON.parse(data.personType)?.contrapartes
      : [],
    risk: data?.risk ? JSON.parse(data.risk) : [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateDocumentRole | any>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {modalType === "create"
          ? "Agregar documento por perfil"
          : "Actualizar documento por perfil"}
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="document"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={documentListData.content}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-passwordDocument";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Documento"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.document}
                          //@ts-ignore
                          helperText={
                            errors.document && errors.document.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="role"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={roleData.filter(
                      (role: any) =>
                        role.name === "Administrador" ||
                        role.name === "Cumplimiento" ||
                        role.name === "Tesoría" ||
                        role.name === "Operaciones" ||
                        role.name === "Análisis y Monitoreo"
                    )}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-passwordRole";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.role}
                          //@ts-ignore
                          helperText={errors.role && errors.role.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6} maxWidth="lg">
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="nacional_institucional"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Personas jurídicas nacionales institucionales
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("nacional_institucional")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.nacional_institucional.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.nacional_institucional.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="patrinomio_autonomos"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Patrimonios autónomos (Institucionales)
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("patrinomio_autonomos")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.patrinomio_autonomos.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.patrinomio_autonomos.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="nacional_sin_fin_lucro"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Personas jurídicas nacionales sin fines de lucro
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("nacional_sin_fin_lucro")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.nacional_sin_fin_lucro.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.nacional_sin_fin_lucro.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Controller
                control={control}
                name="nacionales_profesionales"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onChange}
                          checked={value}
                          {...field}
                        />
                      }
                      label="Personas jurídicas nacionales (profesionales)"
                    />
                  </FormGroup>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="nacional_no_profesional"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Personas jurídicas nacionales no profesionales
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("nacional_no_profesional")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.nacional_no_profesional.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.nacional_no_profesional.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="contrapartes"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Contrapartes
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("contrapartes")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.contrapartes.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.contrapartes.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="risk"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth error={errors?.risk ? true : false}>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Nivel de riesgo
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("risk")}
                      sx={{
                        mt: "0!important",
                        "& .MuiSelect-select": {
                          paddingLeft: "0!important",
                        },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.risk.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.risk.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {errors?.risk && (
                <FormHelperText>
                  <>{errors?.risk?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
              <Controller
                control={control}
                name="requiredJuridic"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Requerido"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateDocumentRoleTreasury;
