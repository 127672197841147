import {
  HouseIcon,
  GearIcon,
  TableIcon,
  FromBracketIcon,
  FileIcon,
  ChartIcon,
  BussinessManIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "./Properties_es";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";

import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "/",
    permissions: ["is_superuser", "DASHBOARD:READ"],
  },
  {
    label: "Reportes",
    icon: <ChartIcon />,
    to: "reports",
    permissions: ["is_superuser", "REPORTS:READ"],
  },
  {
    label: "Clientes",
    icon: <FileIcon />,
    to: "clients",
    permissions: ["is_superuser", "CUSTOMERHISTORY:READ"],
  },
  {
    label: "Perfil Inversionista",
    icon: <BussinessManIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Factores",
        to: "rubrosManagement",
        permissions: ["is_superuser", "is_user", "RUBROS:READ"],
      },
      {
        label: "Registro de Jurídicos",
        to: "legalregistry",
        permissions: ["is_superuser", "is_user", "LEGALREGISTRY:READ"],
      },
      {
        label: "Entidades bancarias",
        to: "bankEntityManagement",
        permissions: ["is_superuser", "is_user", "BANKENTITY:READ"],
      },
      {
        label: "Notarios",
        to: "notaryManagement",
        permissions: ["is_superuser", "is_user", "NOTARY:READ"],
      },
      {
        label: "Sucursales",
        to: "branchOfficeManagement",
        permissions: ["is_superuser", "is_user", "BRANCHOFFICE:READ"],
      },
    ],
    permissions: [
      "is_superuser",
      "INVESTORPROFILEMENU:READ",
      "RUBROS:READ",
      "LEGALREGISTRY:READ",
    ],
  },
  {
    label: "Mantenimientos",
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Documentos",
        to: "documents",
        permissions: ["is_superuser", "DOCUMENTS:READ"],
      },
      {
        label: "Documentos por perfil",
        to: "documentsRole",
        permissions: ["is_superuser", "DOCUMENTSROLE:READ"],
      },
    ],
    permissions: [
      "is_superuser",
      "MAINTENANCEMENU:READ",
      "DOCUMENTS:READ",
      "DOCUMENTSROLE:READ",
    ],
  },
  {
    label: "Requerimientos Documentales Tesorería",
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Documentos por perfil",
        to: "documentsRoleTreasury",
        permissions: ["is_superuser", "DOCUMENTSROLETREASURY:READ"],
      },
    ],
    permissions: ["is_superuser", "DOCUMENTSROLETREASURY:READ"],
  },
  {
    label: "Listas negras",
    icon: <PlaylistAddCheckIcon sx={{ fontSize: "1.25rem" }} />,
    to: "blacklist",
    permissions: ["is_superuser", "BLACKLIST:READ"],
  },
  {
    label: "Configuraciones",
    icon: (
      <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "userManagement",
        permissions: ["is_superuser", "USER:READ"],
      },
      {
        label: "Perfiles",
        to: "roleManagement",
        permissions: ["is_superuser", "ROLE:READ"],
      },
      {
        label: "Permisos",
        to: "authority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
      },
      {
        label: "Correos por perfil",
        to: "emailRoleManagement",
        permissions: ["is_superuser", "AUTHORITIESXROLE:READ"],
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },
  {
    label: properties.com_parval_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superuser", "is_user"],
  },
];
