import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import FancyPaper from "../components/FancyPaper";
import { useApp } from "../hooks/useApp";
import Loading from "../components/utils/Loading";
import { getAddressData, getAllDataJuridic, getInvestmentProfileById } from "../services/investment/InvestmentService";
import LegalForm from '../components/LegalForm';

const ProfileInvertor = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
  } = useApp();
  const [profileInversor, setProfileInversor] = useState<any>([]);
  const [address, setAddres] = useState<any>({
    provinces: {},
    sector: {},
  });
  const [dataJ, setAllDataJ] = useState<any>([])
  const { id } = useParams();
  
  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async () => {
    setLoading && setLoading(true);
    try {

      let data = await getInvestmentProfileById(Number(id));
      let address = await getAddressData()
      let dataJ = await getAllDataJuridic();

      if (data && address) {
        setProfileInversor(data);
        setAddres(address)
        setAllDataJ(dataJ)
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };


  return (
    <>


      <FancyPaper pagetitle="Clientes Inversionistas">
        {isLoading ? <Loading /> : <LegalForm data={profileInversor} address={address} dataJ={dataJ} />}

      </FancyPaper>

    </>
  );
};

export default ProfileInvertor;
