import { CONSTANT } from "./Constants";
export const URL = {

    /** HOME PATH */
    LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/login/twofactor",
    LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/autenticate",
    LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/recoverPasswordAdmin",
    LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/changePassword",
    LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/activate",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

    // Get Documents

    REQUEST_DOCUMENTS_FORM: CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + "/documents/list",

    /** maintenance urls */
    REQUEST_CERTCATEGORY_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${page}/${size}`,
    REQUEST_CERTCATEGORY: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ''}`,

    REQUEST_CERTIFICATE_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

    /** users urls */
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/?page=${page}&size=${size}`,
    REQUEST_USER_LIST_FILTER: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
    REQUEST_ACTIVATE_USER: (code:string, username:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/activate?code=${code}&username=${username}`,

  
  

    /** Role urls */
    REQUEST_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ''}`,
    REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
    REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,

    /** Rubro urls */

    // perfil-maintenance/dataTablesJuridic/
    /* REQUEST_RUBRO_LIST: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/dataTablesJuridic/?page=${page}&size=${size}`, */
    REQUEST_RUBRO_LIST: (page:number,size:number,filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/dataTablesJuridic/${filter !== "" ? '{description}?tableName='+filter : '?page='+page+'&size='+size}`,
    REQUEST_RUBRO_WITH_URL: (url:string, size: number, page: number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/?page=${page}&size=${size}&type=j`,
    REQUEST_CREATE_RUBRO: (url: string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/create`,
    REQUEST_UPDATE_RUBRO: (url: string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/`,
    REQUEST_DELETE_RUBRO: (url: string, id:number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/${id}`,

    // perfil-investment/

    REQUEST_LIST_INVESTMENT: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/users?page=${page}&size=${size}`,
    REQUEST_LIST_INVESTMENT_FILTER: (page:number,size:number, filter:string) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/filter?filter=${filter}&page=${page}&size=${size}`,
    
    
    REQUEST_INVESTMENT_PROFILE_WITH_ID: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/${id}`,
    REQUEST_INVESTMENT_PROFILE_CALCULATE: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/calculate/${id}`,
    /** EmailRole urls */
    REQUEST_EMAIL_ROLE: (roleID?:string|null, filter?:string|null, currentPage?:number, size?:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${roleID ? roleID+'/' : ''}?${filter ? 'filter='+filter+'&' : ''}page=${currentPage}&size=${size}`,
    REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ''}`,
    DELETE_REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ''}`,
    REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

    /** DocumentRole urls */
    REQUEST_DOCUMENT_ROLE_LIST: (filter:string|null, treasury:string, description: string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/list/?${filter ? 'role='+filter+'&' : ""}page=${page}&size=${size}${treasury === "true" ? "&treasury="+treasury : ""}${description !== "" ? "&description="+description : ""}`,
    REQUEST_DOCUMENT_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ''}`,


    /** document */
    REQUEST_DOCUMENT_LIST: (page:number, size:string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/list/?page=${page}&size=${size}`,
    REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
    REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,

    /** authority */
    REQUEST_AUTHORITY_LIST: (filter:string, page:number, size:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,    
    REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
    REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/role/${roleId}?${filter ? "filter="+filter : ""}&page=${page}&size=${size}`,
    /* REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${roleId}/${page}/${size}?filter=${filter}`, */

   

    /** authorityByRole */    
    REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/filter/${roleId}?${filter && 'filter='+filter+"&"}page=${page}&size=${size}`,      
    
    REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

    REQUEST_CLIENT_LIST: (endDate:any, page:number, size:string, startDate:any, ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,    
    REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}?page=${page}&size=${size}`,  
    
    
    /**Dashboard */
    REQUEST_CUSTOMER_STAGE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-stage`,      
    REQUEST_CUSTOMER_PERSON_TYPE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-person-type`,  
    REQUEST_CUSTOMER_REGISTERED: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer`,  
    REQUEST_CUSTOMER_COMPARATION: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/clients-comparation`,     
    REQUEST_CUSTOMER_YEAR: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer-year`,     
    REQUEST_CUSTOMER_LEADS: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/leads`,     

    /**Listas negras */
    REQUEST_BLACK_LIST: (page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/black-list?page=${page}&size=${size}`,
    REQUEST_BLACK_LIST_ID: (id?:number, user?:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/black-list${id ? "/"+id : ""}${user ? "?user="+user : ""}`,

    /** Branch Office */
    REQUEST_BRANCH: (page:number, size:string, filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/branchOffice/${filter !== "" ? "search/?description="+filter : "?page="+page+"&size="+size}&type=J`,
    REQUEST_BRANCH_CUD: (option?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/branchOffice/${option}`,

    /** Notary */
    REQUEST_NOTARY: (page:number, size:string, filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/notary/${filter !== "" ? "search/?description="+filter : "?page="+page+"&size="+size}&type=J`,
    REQUEST_NOTARY_CUD: (option?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/notary/${option}`,
    
    /** Bank Entity */
    REQUEST_BANKENTITY_SEARCH: (filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/bankEntity/search/${filter !== "" ? "?description="+filter : ""}`,
    REQUEST_BANKENTITY: (page:number, size:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/bankEntity/?page=${page}&size=${size}`, 
    REQUEST_BANKENTITY_CUD: (option?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/bankEntity/${option}`,
    
   
}



