import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import ComparativeGraph from "../components/ComparativeGraph";
import CardDashboard from "../components/CardDashboard";
import PieChartDashboard from "../components/PieChartDashboard";
import {
  getRequestCustomerStage,
  getRequestCustomerPersonType,
  getRequestCustomerRegistered,
  getRequestCustomerComparation,
  getRequestCustomerLeads,
} from "../services/DashboardServices";
import { useApp } from "../hooks/useApp";
import { useForm } from "react-hook-form";

interface searchData {
  fromDate?: Date | null;
  toDate?: Date | null;
}

const defaultValues: searchData = {
  fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
};

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();
  const [customerStage, setCustomerStage] = useState<any>(null);
  const [customerPersonType, setCustomerPersonType] = useState<any>(null);
  const [customerRegistered, setCustomerRegistered] = useState<any>(null);
  const [customerComparation, setCustomerComparation] = useState<any>(null);
  const [customerLeads, setCustomerLeads] = useState<any>(null);
  const { handleSubmit } = useForm<searchData>({
    defaultValues,
  });

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchCustomerStage(defaultValues);
      await handleFetchCustomerPersonType(defaultValues);
      await handleFetchCustomerRegistered(defaultValues);
      await handleFetchCustomerLeads(defaultValues);
      await handleFetchCustomerComparation();
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleFetchCustomerRegistered = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerRegistered = await getRequestCustomerRegistered(filter);
      if (dataCustomerRegistered) {
        setCustomerRegistered(dataCustomerRegistered);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchCustomerLeads = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerLeads = await getRequestCustomerLeads(filter);
      if (dataCustomerLeads) {
        setCustomerLeads(dataCustomerLeads);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchCustomerPersonType = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerPersonType = await getRequestCustomerPersonType(filter);
      if (dataCustomerPersonType) {
        setCustomerPersonType(dataCustomerPersonType);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchCustomerStage = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerStage = await getRequestCustomerStage(filter);
      if (dataCustomerStage) {
        setCustomerStage(dataCustomerStage);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchCustomerComparation = async () => {
    setLoading && setLoading(true);
    try {
      let dataCustomerComparation = await getRequestCustomerComparation();
      if (dataCustomerComparation) {
        setCustomerComparation(dataCustomerComparation);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case "customerStage":
        console.log("entra" + dataName);
        await handleFetchCustomerStage(defaultValues);
        break;
      case "customerComparation":
        console.log("entra" + dataName);
        await handleFetchCustomerComparation();
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        p: {
          xs: 5,
          md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
        },
      }}
    >
      <Typography
        variant="caption"
        color="info.dark"
        onClick={() => navigate("/")}
        sx={{
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        Inicio{" "}
      </Typography>
      <Typography variant="caption" color="text.primary">
        {"/ "}
      </Typography>
      <Divider sx={{ mb: "40px" }} />
      <Grid container spacing={6}>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              customerRegistered !== null && customerRegistered[1]
                ? customerRegistered[1][2]
                : 0
            }
            title="Clientes registrados"
            subtitle="En el último mes"
            colorIcon="#5271ff"
            iconCard={<PersonAddAlt1Icon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              customerPersonType !== null && customerPersonType[2]
                ? customerPersonType[2][1]
                : 0
            }
            title="Clientes persona física"
            subtitle="En el último mes"
            colorIcon="#f04438"
            iconCard={<AssignmentIndIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              customerPersonType !== null && customerPersonType[1]
                ? customerPersonType[1][1]
                : 0
            }
            title="Clientes persona jurídica"
            subtitle="En el último mes"
            colorIcon="#06833c"
            iconCard={<AccountBalanceIcon />}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CardDashboard
            positive
            sx={{ height: "100%" }}
            value={
              customerLeads !== null && customerLeads[1]
                ? customerLeads[1][2]
                : 0
            }
            title="Clientes leads"
            subtitle="En el último mes"
            colorIcon="#f1b80e"
            iconCard={<PersonAddDisabledIcon />}
          />
        </Grid>
        <Grid item md={7} xs={12}>
          {customerComparation !== null && (
            <ComparativeGraph
              title="Clientes registrados por mes últimos 2 años"
              chartSeries={customerComparation}
              sx={{ height: "100%" }}
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
        <Grid item md={5} xs={12}>
          {customerStage !== null && (
            <PieChartDashboard
              title="Clientes por fase último mes"
              data={customerStage}
              titleChart=" "
              handleClick={handleSubmit(onSubmit)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
