import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  Checkbox,
  styled,
  useTheme,
  InputLabel,
  Switch,
  FormControlLabel,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import InputLabelToForm from "../InputLabelToForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import { formSchemaOptionsCountry } from "../../types/Utils";

interface blackListType {
  name: string;
  surname: string;
  alias: string;
  client_type: string;
  nationality: object;
  source: string;
  document_type: string;
  document_number: string;
  is_client: boolean;
}

const AddOrUpdateBlackList = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  country,
}: DialogProps) => {
  const theme = useTheme();
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    surname: Yup.string().required("Campo es requerido"),
    alias: Yup.string().required("Campo es requerido"),
    client_type: Yup.string().required("Campo es requerido"),
    nationality: Yup.object()
      .shape(formSchemaOptionsCountry)
      .typeError("Campo es requerido"),
    source: Yup.string().required("Campo es requerido"),
    document_type: Yup.string().required("Campo es requerido"),
    document_number: Yup.string().required("Campo es requerido"),
    is_client: Yup.boolean(),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    name: data?.name ?? "",
    surname: data?.surname ?? "",
    alias: data?.alias ?? "",
    client_type: data?.client_type ?? "",
    nationality: data?.nationality
      ? country?.find((d: any) => Number(d.id) === Number(data?.nationality))
      : [],
    source: data?.source ?? "",
    document_type: data?.document_type ?? "",
    document_number: data?.document_number ?? "",
    is_client: data?.is_client ?? false,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<blackListType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle>
        {modalType === "create"
          ? "Agregar persona a la lista"
          : "Actualizar persona"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12} sm={6}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre"
                  type="text"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("name")}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={"surname"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Apellido"
                  type="text"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("surname")}
                  error={errors.surname && Boolean(errors.surname)}
                  helperText={errors.surname && errors.surname.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={"alias"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Alias o apodo"
                  type="text"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("alias")}
                  error={errors.alias && Boolean(errors.alias)}
                  helperText={errors.alias && errors.alias.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={"client_type"}
              control={control}
              render={({ field: { value } }) => (
                <FormControl fullWidth>
                  <InputLabel sx={{ marginTop: "7px", marginLeft: "-14px" }}>
                    Tipo de cliente
                  </InputLabel>
                  <Select
                    variant="standard"
                    value={value}
                    {...register("client_type")}
                  >
                    <MenuItem value="F">Físico</MenuItem>
                    <MenuItem value="J">Jurídico</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={"source"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Fuente"
                  type="text"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("source")}
                  error={errors.source && Boolean(errors.source)}
                  helperText={errors.source && errors.source.message}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={2}>
            <Controller
              name={"document_type"}
              control={control}
              render={({ field: { value } }) => (
                <FormControl fullWidth>
                  <InputLabel sx={{ marginTop: "7px", marginLeft: "-14px" }}>
                    Tipo
                  </InputLabel>
                  <Select
                    variant="standard"
                    value={value}
                    {...register("document_type")}
                  >
                    <MenuItem value="CI">Cédula</MenuItem>
                    <MenuItem value="RNC">RNC</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <Controller
              name={"document_number"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Número documento"
                  type="text"
                  variant="standard"
                  value={value}
                  sx={{ "& input": { pl: "0!important" } }}
                  {...register("document_number")}
                  error={
                    errors.document_number && Boolean(errors.document_number)
                  }
                  helperText={
                    errors.document_number && errors.document_number.message
                  }
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="nationality"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={country ?? []}
                  getOptionLabel={(option: any) => option?.country || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value.id
                  }
                  //@ts-ignore
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Nacionalidad"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        error={!!errors.nationality}
                        helperText={
                          errors.nationality && errors.nationality.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Controller
              name={"is_client"}
              control={control}
              render={({ field: { value } }) => (
                <FormControlLabel
                  checked={value}
                  control={
                    <Switch {...register("is_client")} color="primary" />
                  }
                  label="¿Es cliente?"
                  labelPlacement="start"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateBlackList;
