import {URL} from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import Urls from "../../url/Urls";
import { StringLiteral } from "typescript";

export const getRequestBranch = async (page:number, filter:string, size?:string) => {
  let responseData = {};  
  const params = new URLSearchParams([    
    ["description", filter],
    ["type", "J"]
  ]);
  let keysParams:any = [];
  params.forEach((value, key) => {
    if (value === '' || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key:any) => {
    params.delete(key);
  });  
  await privateFetch
    .get(
      URL.REQUEST_BRANCH(page,
        size ?? Urls.registration_x_page,
         filter
        ) 
      
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const addUpdRequestBranch  = async (data:any, option:string) => {
  let responseData = {}; 
  await privateFetch
    .put(URL.REQUEST_BRANCH_CUD(option), data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};


export const deleteRequestBranch = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_BRANCH_CUD(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error.message)
    });
  return responseData;
};
