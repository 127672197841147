import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps, branchOfficeType } from "../../types/Utils";
import { en_es } from "../../pages/utils/I18n";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

const AddOrUpdateRubro = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  branchOffice,
}: DialogProps) => {
  const validationSchema = (nameInputs: DataType) =>
    Yup.object().shape(
      Object.keys(nameInputs).reduce((schema, key) => {
        return {
          ...schema,
          [key]: Yup.string().required("Campo es requerido"),
        };
      }, {})
    );

  const { id, tableName, type, weight, ...nameInputs } = data;

  type DataType = typeof nameInputs;

  const validation = validationSchema(nameInputs);

  const defaultValues = Object.keys(data).reduce((values, key) => {
    return {
      ...values,
      [key]: data[key] || "",
    };
  }, {});

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<DataType>({
    defaultValues: modalType === "create" ? {} : defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar Factor" : "Actualizar Factor"}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            {Object.keys(nameInputs).map((key, index) =>
              key !== "branchOffice" ? (
                <Grid item xs={12} key={key}>
                  <Controller
                    name={key}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label={en_es[key]}
                        type="text"
                        variant="standard"
                        value={value}
                        sx={{ "& input": { pl: "0!important" } }}
                        onChange={(e) => onChange(e.target.value)}
                        error={errors[key] && Boolean(errors[key])}
                        // @ts-ignore
                        helperText={errors[key] && errors[key].message}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} key={key}>
                  <Controller
                    name={key}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl fullWidth>
                        <InputLabel
                          sx={{ marginTop: "7px", marginLeft: "-14px" }}
                        >
                          {en_es[key]}
                        </InputLabel>
                        <Select
                          variant="standard"
                          value={value}
                          onChange={(e) => onChange(e.target.value)}
                        >
                          {branchOffice?.map((n: branchOfficeType) => (
                            <MenuItem value={n.id} key={n.id}>
                              {n.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              )
            )}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRubro;
