import {
  Container,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  Box,
  Button,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import FeedIcon from "@mui/icons-material/Feed";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import Headroom from "react-headroom";

import { useState } from "react";
import BussinessIdentification from "./steps/BussinessIdentification";
import EconomicActivity from "./steps/EconomicActivity";
import AdditionalLegalInformation from "./steps/AdditionalLegalInformation";
import BeneficiariesAndMarketKnowledge from "./steps/BeneficiariesAndMarketKnowledge";
import ButtonGradient from "./ButtonGradient";
import InvestorTypeResults from "./steps/InvestorTypeResults";

type StepsType = {
  label: string;
  component: JSX.Element;
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: `${theme.palette.secondary.dark}`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "inherit",
  ...(ownerState.active && {
    backgroundColor: `${theme.palette.secondary.dark}`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.35)",
  }),
  ...(ownerState.completed && {
    backgroundColor: `${theme.palette.secondary.dark}`,
  }),
}));

const WrappMobileStepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.dark}`,
  color: "#fff",
  fontFamily: "Mulish",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 30 }} />,
    2: <CreditScoreIcon sx={{ fontSize: 30 }} />,
    3: <FeedIcon sx={{ fontSize: 30 }} />,
    4: <PeopleAltIcon sx={{ fontSize: 30 }} />,
    5: <SensorOccupiedIcon sx={{ fontSize: 30 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const LegalForm = ({ data, address, dataJ }: any) => {
  const steps: StepsType[] = [
    {
      label: "Conozca a su cliente",
      // @ts-ignore
      component: (
        <BussinessIdentification
          // @ts-ignore
          data={data.fields}
          address={address}
          dataJ={dataJ}
        />
      ),
    },
    {
      label: "Información Adicional",
      // @ts-ignoreL
      component: <AdditionalLegalInformation dataStep2={data.fields} />,
    },
    {
      label: "Perfil del Inversionista",
      // @ts-ignore
      component: <EconomicActivity dataStep3={data.fields} dataJ={dataJ} />,
    },

    {
      label: "Beneficiarios y Conocimiento del Mercado",
      // @ts-ignore
      component: (
        <BeneficiariesAndMarketKnowledge
          // @ts-ignore
          dataStep4={data.fields}
          dataJ={dataJ}
        />
      ),
    },
    {
      label: "Resultados del Inversionista",
      // @ts-ignore
      component: (
        <InvestorTypeResults
          userId={data.userId}
          dataStep5={data.fields}
          dataJ={dataJ}
        />
      ),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const maxSteps = steps.length;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step);
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5, pb: 8 }}>
      <Stack sx={{ width: "100%" }} spacing={4}>
        {!isMobile ? (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((step, i) => (
              <Step key={i}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  onClick={handleStep(i)}
                  sx={{ cursor: "pointer" }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          <Headroom downTolerance={10} pinStart={80}>
            <WrappMobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep >= maxSteps - 1}
                  sx={{ color: "#fff" }}
                >
                  Continuar
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{ color: "#fff" }}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Regresar
                </Button>
              }
            />
          </Headroom>
        )}

        <>
          {data.formType && steps[activeStep].component}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Regresar
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <ButtonGradient
              onClick={handleNext}
              disabled={activeStep === steps.length - 1}
            >
              {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
            </ButtonGradient>
          </Box>
          {/* )} */}
        </>
      </Stack>
    </Container>
  );
};

export default LegalForm;
