import { FC } from "react";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  InputLabel,
  styled,
} from "@mui/material";
import TitleForm from "../../components/TitleForm";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { data as datos } from "../../data/data";

const BeneficiariesAndMarketKnowledge: FC = ({ dataStep4, dataJ }: any) => {
  const defaultValues: any = {
    company_have_a_TIN_or_EIN: dataStep4.company_have_a_TIN_or_EIN
      ? dataStep4.company_have_a_TIN_or_EIN
      : "",
    tin_or_ein: dataStep4.tin_or_ein ? dataStep4.tin_or_ein : "",
    company_have_a_GIN: dataStep4.company_have_a_GIN
      ? dataStep4.company_have_a_GIN
      : "",
    company_have_a_GIN_data: dataStep4.company_have_a_GIN_data
      ? dataStep4.company_have_a_GIN_data
      : "",
    direct_and_indirect_beneficiaries:
      (dataStep4.direct_and_indirect_beneficiaries &&
        JSON.parse(dataStep4.direct_and_indirect_beneficiaries)) ?? [
        {
          full_name: "",
          document_type: [],
          identification_number: "",
          occupation: "",
          phone: "",
          address: "",
        },
      ],
    authorized_signatures: (dataStep4.authorized_signatures &&
      JSON.parse(dataStep4.authorized_signatures)) ?? [
      {
        full_name: "",
        document_number: "",
      },
    ],
    mode_of_signatures_and_reception_of_instructions:
      dataStep4?.mode_of_signatures_and_reception_of_instructions ?? "",
    liquid_assets_distribution_form:
      (dataStep4.liquid_assets_distribution_form &&
        JSON.parse(dataStep4.liquid_assets_distribution_form)) ?? [
        {
          liquid_assets_distribution_id: [],
          liquid_assets_percentage: 0,
        },
      ],
    information_media: dataStep4.information_media
      ? dataJ.pa_ta_information_media.find(
          (f: any) => f.id === Number(dataStep4.information_media)
        )
      : null,
    financialInstrumentsYear: (dataStep4.financialInstrumentsYear &&
      JSON.parse(dataStep4.financialInstrumentsYear)) ?? [
      {
        financialInstruments: null,
        experienceTime: null,
        numTransactionsYear: null,
        levelknowInstRisk: null,
      },
    ],
    operations_in_national_or_foreign_stock_market:
      dataStep4.operations_in_national_or_foreign_stock_market
        ? dataStep4.operations_in_national_or_foreign_stock_market
        : "",
    traded_volume_greater_than_3000: dataStep4.traded_volume_greater_than_3000
      ? dataStep4.traded_volume_greater_than_3000
      : "",
    average_transaction_frequency: dataStep4.average_transaction_frequency
      ? dataStep4.average_transaction_frequency
      : "",
    has_cash_or_investment_assets: dataStep4.has_cash_or_investment_assets
      ? dataStep4.has_cash_or_investment_assets
      : "",
    positions_in_management_of_financial_institution:
      dataStep4.positions_in_management_of_financial_institution
        ? dataStep4.positions_in_management_of_financial_institution
        : "",
    member_of_the_management_board: dataStep4.member_of_the_management_board
      ? dataStep4.member_of_the_management_board
      : "",
    stock_broker_certification: dataStep4.stock_broker_certification
      ? dataStep4.stock_broker_certification
      : "",
  };

  const { register, control, watch } = useForm({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { fields: direct_and_indirect_beneficiariesFields } = useFieldArray({
    control,
    name: "direct_and_indirect_beneficiaries",
    rules: {
      minLength: 1,
    },
  });

  const { fields: authorized_signaturesFields } = useFieldArray({
    control,
    name: "authorized_signatures",
    rules: {
      minLength: 1,
    },
  });

  const { fields: financialInstrumentsYearField } = useFieldArray({
    control,
    name: "financialInstrumentsYear",
    rules: {
      minLength: 3,
    },
  });
  const { fields: liquid_assets_distributionField } = useFieldArray({
    control,
    name: "liquid_assets_distribution_form",
    rules: {
      minLength: 3,
    },
  });
  const watch_company_have_a_TIN_or_EIN: any = watch(
    "company_have_a_TIN_or_EIN"
  );
  const watch_company_have_a_GIN: any = watch("company_have_a_GIN");
  const watch_operations_in_national_or_foreign_stock_market: any = watch(
    "operations_in_national_or_foreign_stock_market"
  );

  return (
    <form>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Relativo a FATCA (Foreign Account Tax Compliance Act)
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="company_have_a_TIN_or_EIN"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la empresa un Tax Identification Number (TIN) o Employer Identification Number (EIN)?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_company_have_a_TIN_or_EIN === "Sí" && (
          <Grid item xs={12}>
            <Controller
              name={"tin_or_ein"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="	TIN o EIN"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled
                  autoComplete="tin_or_ein"
                  {...register("tin_or_ein")}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="company_have_a_GIN"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la empresa un GIIN (Global Intermediary Identification Number)?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_company_have_a_GIN === "Sí" && (
          <Grid item xs={12}>
            <Controller
              name={"company_have_a_GIN_data"}
              control={control}
              render={({ field: { value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="GIIN (Global Intermediary Identification Number)"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled
                  autoComplete="company_have_a_GIN_data"
                  {...register("company_have_a_GIN_data")}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Declaración de beneficiarios directos / indirectos / relacionados
      </TitleForm>

      {direct_and_indirect_beneficiariesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre(s) y apellido(s)"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.full_name` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`direct_and_indirect_beneficiaries.${index}.document_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.id_type}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de documento"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nro. de documento"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.identification_number` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.occupation`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Ocupación"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.occupation` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.phone`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.phone` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`direct_and_indirect_beneficiaries.${index}.address`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Dirección"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `direct_and_indirect_beneficiaries.${index}.address` as const
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Tarjeta de Firmas (Firmantes autorizados)
      </TitleForm>
      {authorized_signaturesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorized_signatures.${index}.full_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre completo"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `authorized_signatures.${index}.full_name` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`authorized_signatures.${index}.document_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Documento de identidad"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `authorized_signatures.${index}.document_number` as const
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="mode_of_signatures_and_reception_of_instructions"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Controller
                name={`mode_of_signatures_and_reception_of_instructions`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Modalidad de Firmas y Recepción de Instrucciones"
                    type="text"
                    variant="standard"
                    disabled
                    {...register(
                      "mode_of_signatures_and_reception_of_instructions"
                    )}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {liquid_assets_distributionField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_liquid_assets_distribution}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione Activo Liquido"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                sx={{ display: "flex", alignItems: "flex-end" }}
              >
                <Controller
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_percentage`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Porcentaje"
                      type="number"
                      variant="standard"
                      disabled
                      {...register(
                        `liquid_assets_distribution_form.${index}.liquid_assets_percentage` as const
                      )}
                      inputProps={{ inputMode: "numeric", min: 0, max: 100 }}
                    />
                  )}
                />
                <Typography variant="body1" color="initial">
                  %
                </Typography>
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Conocimiento General del Mercado
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="information_media"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ.pa_ta_information_media}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Medios de Información utilizados sobre los mercados Financieros"
                    variant="standard"
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
        Instrumentos financieros negociados previamente
      </TitleForm>
      {financialInstrumentsYearField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.financialInstruments`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_instrument}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Instrumentos"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.experienceTime`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_time_experience_instrument}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tiempo de experiencia"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.numTransactionsYear`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_number_operations_year}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Cantidad de Operaciones al año"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.levelknowInstRisk`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_knowledge_level}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nivel de conocimiento instrumento y riesgos asociados"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <TitleForm sx={{ mt: 5, mb: 4 }}>
        Clasificación del inversionista
      </TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        A. Experiencia
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="operations_in_national_or_foreign_stock_market"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete =
                    "operations_in_national_or_foreign_stock_market";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha realizado operaciones en el mercado valores nacional o extranjero?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_operations_in_national_or_foreign_stock_market === "Sí" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="traded_volume_greater_than_3000"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={datos.pa_ta_yes_or_no}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  //@ts-ignore
                  value={value}
                  disabled
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "traded_volume_greater_than_3000";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="De ser afirmativa la respuesta anterior, ¿el volumen transado en dichas operaciones ha sido igual o superior a DOP 3,000,000.00 o su equivalente en cualquier otra moneda por año?"
                        variant="standard"
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            control={control}
            name="average_transaction_frequency"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "average_transaction_frequency";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Las transacciones realizadas han tenido una frecuencia media de tres (3) operaciones por trimestre en el año?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        B. Capacidad patrimonial
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="has_cash_or_investment_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "has_cash_or_investment_assets";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee activos en efectivo o inversiones en instrumentos financieros igual o mayor a DOP 25,000,000.00 o su equivalente a cualquier otra moneda, depositados en una entidad de intermediación financiera o inversiones
                      locales o extranjeras?            "
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4, textAlign: "left" }}>
        C. Conocimiento
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="positions_in_management_of_financial_institution"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete =
                    "positions_in_management_of_financial_institution";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha laborado en cargos de dirección en el área de negocios o área afines de una entidad que opere en el sistema financiero por un período de al menos dos (2) años?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="member_of_the_management_board"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "member_of_the_management_board";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Ha sido miembro del consejo de administración de una entidad que opere en el sistema financiero por un
                      período de al menos dos (2) años?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="stock_broker_certification"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.pa_ta_yes_or_no}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "stock_broker_certification";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Tiene una certificación de corredor de valores o su equivalente de otro país?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BeneficiariesAndMarketKnowledge;
