import { FC } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Divider,
  FormControl,
  Select,
  MenuItem,
  Box,
  Checkbox,
  Chip,
  ListItemText,
  InputLabel,
  styled,
} from "@mui/material";
import TitleForm from "../../components/TitleForm";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { data as datos } from "../../data/data";

const LabelForm = styled(InputLabel)(() => ({
  left: "-14px",
}));

const EconomicActivity: FC = ({ dataStep3, dataJ }: any) => {
  const defaultValues: any = {
    annual_income: dataStep3.annual_income
      ? dataJ.pa_ta_annual_income.find(
          (f: any) => f.id === Number(dataStep3.annual_income)
        )
      : [],
    level_experience_and_knowledge_as_investor:
      dataStep3?.level_experience_and_knowledge_as_investor
        ? dataJ.pa_ta_knowledge_level.find(
            (f: any) =>
              f.id ===
              Number(dataStep3.level_experience_and_knowledge_as_investor)
          )
        : [],
    total_assets: dataStep3.total_assets
      ? dataJ.pa_ta_total_liquid_assets.find(
          (f: any) => f.id === Number(dataStep3.total_assets)
        )
      : [],
    total_heritage: dataStep3.total_heritage
      ? dataJ.pa_ta_total_heritage.find(
          (f: any) => f.id === Number(dataStep3.total_heritage)
        )
      : [],
    accounts_payable: dataStep3.accounts_payable
      ? dataJ.pa_ta_accounts_payable.find(
          (f: any) => f.id === Number(dataStep3.accounts_payable)
        )
      : [],
    investment_horizon: dataStep3.investment_horizon
      ? dataJ.pa_ta_investment_time_horizon.find(
          (f: any) => f.id === Number(dataStep3.investment_horizon)
        )
      : "",
    annual_expenses: dataStep3.annual_expenses
      ? dataJ.pa_ta_annual_expenses.find(
          (f: any) => f.id === Number(dataStep3.annual_expenses)
        )
      : [],
    emergency_fund_for_expenses: dataStep3.emergency_fund_for_expenses
      ? dataJ.pa_ta_emergency_fund?.find(
          (f: any) => f.id === Number(dataStep3.emergency_fund_for_expenses)
        )
      : [],
    destination_income: dataStep3.destination_income
      ? dataJ.pa_ta_destination_income.find(
          (f: any) => f.id === Number(dataStep3.destination_income)
        )
      : [],
    ability_absorb_losses: dataStep3.ability_absorb_losses
      ? dataJ &&
        dataJ.pa_ta_ability_absorb_losses.find(
          (f: any) => f.id === Number(dataStep3.ability_absorb_losses)
        )
      : [],
    risk_tolerance: dataStep3.risk_tolerance
      ? dataJ &&
        dataJ.pa_ta_risk_tolerance.find(
          (f: any) => f.id === Number(dataStep3.risk_tolerance)
        )
      : [],
    investment_goals: dataStep3.investment_goals
      ? dataJ &&
        dataJ.pa_ta_investment_goals.find(
          (f: any) => f.id === Number(dataStep3.investment_goals)
        )
      : [],
    desired_profitability: dataStep3.desired_profitability
      ? dataJ &&
        dataJ.pa_ta_desired_profitability.find(
          (f: any) => f.id === Number(dataStep3.desired_profitability)
        )
      : [],
    main_sources_funds: dataStep3.main_sources_funds
      ? JSON.parse(dataStep3.main_sources_funds)
      : [],
    other_main_sources_funds: dataStep3.other_main_sources_funds
      ? dataStep3.other_main_sources_funds
      : "",
    operations_values_finance: dataStep3.operations_values_finance
      ? JSON.parse(dataStep3.operations_values_finance)
      : [],
    average_annual_amount: dataStep3.average_annual_amount
      ? dataJ.pa_ta_initial_investment_amount.find(
          (f: any) => f.id === Number(dataStep3.average_annual_amount)
        )
      : [],
    financialactivities: dataStep3.financialactivities
      ? dataJ.pa_ta_economic_activity.find(
          (f: any) => f.id === Number(dataStep3.financialactivities)
        )
      : [],
    relationship_duration: dataStep3.relationship_duration
      ? dataJ?.pa_ta_relationship_duration?.find(
          (f: any) => f.id === Number(dataStep3.relationship_duration)
        )
      : [],
    operation_time: dataStep3.operation_time
      ? dataJ?.pa_ta_operation_time?.find(
          (f: any) => f.id === Number(dataStep3.operation_time)
        )
      : [],
    bank_accounts_register: (dataStep3.bank_accounts_register &&
      JSON.parse(dataStep3.bank_accounts_register)) ?? [
      {
        account_holder: "",
        institution: "",
        account_type: "",
        account_number: "",
        currency_type: "",
        aba_or_swift: "",
      },
    ],
  };

  const { register, handleSubmit, control, watch } = useForm({
    defaultValues,
  });
  const watch_main_sources_funds: any = watch("main_sources_funds");

  const { fields: bank_accounts_registerFields } = useFieldArray({
    control,
    name: "bank_accounts_register",
    rules: {
      minLength: 1,
    },
  });

  const onSubmit = async (data: any) => {
    console.log("data", data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Información financiera</TitleForm>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ && dataJ.pa_ta_annual_income}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ingresos anuales"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="level_experience_and_knowledge_as_investor"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_knowledge_level}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nivel de experiencia y conocimiento como inversionista"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="total_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ && dataJ.pa_ta_annual_income}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total activos líquidos"
                      variant="standard"
                      autoComplete="off"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                // options={dataJ && dataJ.pa_ta_total_heritage}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio total"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="accounts_payable"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.pa_ta_accounts_payable}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cuentas por pagar"
                      variant="standard"
                      autoComplete="off"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_horizon"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ.pa_ta_investment_time_horizon}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Horizonte de Inversión"
                    variant="standard"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ && dataJ.pa_ta_annual_expenses}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Gastos anuales"
                      variant="standard"
                      autoComplete="off"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="emergency_fund_for_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                //options={datos.pa_ta_emergency_fund_for_expenses}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Mantiene un fondo de emergencia para cubrir gastos imprevistos?"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="destination_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                //options={datos.pa_ta_destination_income}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "destination_income";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Destino de los intereses"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="ability_absorb_losses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ?.pa_ta_ability_absorb_losses}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Capacidad de absorber pérdidas de capital"
                    variant="standard"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_goals"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                //options={dataJ && dataJ.pa_ta_investment_goals}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Objetivos/Tolerancia al riesgo ¿Cuál de estos criterios se ajusta más a sus ideas de rentabilidad/riesgo?"
                    variant="standard"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="risk_tolerance"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ.pa_ta_risk_tolerance}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tolerancia al riesgo"
                    variant="standard"
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="desired_profitability"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={dataJ.pa_ta_desired_profitability}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "desired_profitability3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Rentabilidad deseada"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="main_sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled>
                <LabelForm>Procedencia de fondos</LabelForm>
                <Select
                  variant="standard"
                  value={value}
                  multiple
                  {...register("main_sources_funds")}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.pa_ta_main_sources_funds2.find(
                              (item: any) => item.id === value
                            )?.description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: "2px" }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {datos.pa_ta_main_sources_funds2.map((item: any, i: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox checked={value.indexOf(item.id) > -1} />
                      <ListItemText primary={item.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        {watch_main_sources_funds.some((item: any) => item === 8) && (
          <Grid item sm={6} xs={12}>
            <Controller
              name={"other_main_sources_funds"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Otra procedencia de fondos"
                  type="text"
                  variant="standard"
                  disabled
                  autoComplete="other_main_sources_funds"
                  {...register("other_main_sources_funds")}
                />
              )}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="operations_values_finance"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled>
                <LabelForm>
                  Operaciones de títulos valores que comprende
                </LabelForm>
                <Select
                  variant="standard"
                  value={value}
                  multiple
                  {...register("operations_values_finance")}
                  renderValue={(selected: any) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            dataJ.pa_ta_operations_values_finance.find(
                              (item: any) => item.id === value
                            )?.description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: "2px" }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {dataJ.pa_ta_operations_values_finance.map(
                    (item: any, i: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="average_annual_amount"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                // options={dataJ && dataJ.pa_ta_initial_investment_amount}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Monto inicial de inversión"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="financialactivities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Actividades financieras"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="relationship_duration"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Duración de la relación"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="operation_time"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={[]}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tiempo de operación"
                      variant="standard"
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm sx={{ mt: 8, mb: 4 }}>
        Designacion de cuenta bancaria
      </TitleForm>
      {bank_accounts_registerFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_holder`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Titular de la cuenta"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `bank_accounts_register.${index}.account_holder` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.institution`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={[]}
                      getOptionLabel={(option: any) => option.name || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Institución"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.account_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.pa_ta_account_type}
                      getOptionLabel={(option: any) => option || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option === value
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de cuenta"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de cuenta"
                      type="text"
                      variant="standard"
                      disabled
                      {...register(
                        `bank_accounts_register.${index}.account_number` as const
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.currency_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      // options={dataJ.pa_ta_currency_preference}
                      options={[]}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Moneda"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
    </form>
  );
};

export default EconomicActivity;
