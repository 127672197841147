import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TextField,
  Autocomplete,
  Button,
  Stack,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Tooltip,
  styled,
} from "@mui/material";
import InputLabelToForm from "../components/InputLabelToForm";
import FancyPaper from "../components/FancyPaper";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { getRequest, getStoresClient } from "../services/ClientsServices";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../hooks/useApp";
import { data as datos } from "../resources/data/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DownIcon } from "../components/svgicons/SvgIcons";
import { formatDate } from "../utils/UtilsFunctions";
import { CONSTANT } from "../utils/Constants";

const Title = styled(DialogTitle)(() => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(() => ({
  borderTop: "1px solid #dee2e6",
}));

const validation = Yup.object().shape({
  startDate: Yup.date().typeError(""),
  endDate: Yup.date().typeError(""),
  code: Yup.string(),
  name: Yup.string(),
  identification: Yup.string(),
  personType: Yup.string().nullable(),
});

interface searchClientsTypes {
  startDate?: Date | null;
  endDate?: Date | null;
  code?: string;
  name?: string;
  identification?: string;
  personType?: string;
  fileForm?: string;
}

const defaultValues: searchClientsTypes = {
  startDate: new Date("01/01/2023"),
  endDate: new Date(),
  code: "",
  name: "",
  identification: "",
  personType: "",
  fileForm: "",
};

const ClientManagement = () => {
  const { setLoading, setErrorMsg, modalData, setModalData, errorMsg } =
    useApp();
  const [page, setPage] = useState(0);
  const { register, handleSubmit, formState, control, watch, reset } =
    useForm<searchClientsTypes>({
      defaultValues,
      resolver: yupResolver(validation),
    });
  const { errors } = formState;
  const watch_personType: any = watch("personType");
  const [preFilter, setPreFilter] = useState<any>(defaultValues);
  const [client, setClient] = useState<any>([]);
  const [store, setStore] = useState<any>([]);
  const [customLoading, setCustomLoading] = useState(false);
  const [customPage, setcustomPage] = useState(0);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, defaultValues);
    };
    dataInit();
    // eslint-disable-next-line
  }, []);

  const handleClearFilter = async (e: any) => {
    setPreFilter(defaultValues);
    await handleFetchData(0, defaultValues);
    reset(defaultValues);
  };

  const handleFetchData = async (currentPage: number, filter: any) => {
    setLoading && setLoading(true);
    try {
      //setPreFilter(filter);
      setPage(currentPage);

      let data = await getRequest(currentPage, filter);
      if (data) {
        setClient(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleStoresClient = async (id: string, currentPage: number) => {
    setCustomLoading(true);
    try {
      setcustomPage(currentPage);
      let dataStores = await getStoresClient(id, currentPage);
      if (dataStores) {
        setStore(dataStores);
      }
      setCustomLoading(false);
    } catch (error: any) {
      setCustomLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleCustomChangePage = async (event: unknown, newPage: number) => {
    setcustomPage(newPage - 1);
    let customPageModal = newPage - 1;
    if (customPageModal !== customPage) {
      //@ts-ignore
      await handleStoresClient(modalData?.modalObject, customPageModal);
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "details") {
      await handleStoresClient(id, 0);
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: id,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      default:
        await handleFetchData(0, data);
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Clientes">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtrar por:
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name="personType"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={datos.personType}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de persona"
                      variant="standard"
                      error={!!errors.personType}
                      helperText={
                        errors.personType && errors.personType.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              control={control}
              name="fileForm"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    onChange(newValue);
                  }}
                  options={datos.fileForm}
                  getOptionLabel={(option: any) => option || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option === value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Modalidad de firma"
                      variant="standard"
                      error={!!errors.fileForm}
                      helperText={errors.fileForm && errors.fileForm.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"identification"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={watch_personType === "Física" ? "Cédula" : "RNC"}
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("identification")}
                  error={
                    errors.identification && Boolean(errors.identification)
                  }
                  helperText={
                    errors.identification && errors.identification.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"code"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Código de cliente"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("code")}
                  error={errors.code && Boolean(errors.code)}
                  helperText={errors.code && errors.code.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="Nombre"
                  type="text"
                  variant="standard"
                  fullWidth
                  value={value}
                  {...register("name")}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={{ marginTop: "16px" }}>
            <Controller
              name={"startDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.startDate && Boolean(errors.startDate)}
                  >
                    Fecha desde
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={
                      new Date(
                        "Thu Jan 01 2023 20:35:30 GMT-0400 (Venezuela Time)"
                      )
                    }
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.startDate && (
                <FormHelperText>{errors.startDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={{ marginTop: "16px" }}>
            <Controller
              name={"endDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.endDate && Boolean(errors.endDate)}
                  >
                    Fecha hasta
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={
                      new Date(
                        "Thu Jan 13 2023 20:35:30 GMT-0400 (Venezuela Time)"
                      )
                    }
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.endDate && (
                <FormHelperText>{errors.endDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                //onClick={handleApplyFilter}
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Buscar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Identificación</TableCell>
                <TableCell>Tipo de Persona</TableCell>
                <TableCell>Fecha de Creación</TableCell>
                <TableCell>Fase</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {client &&
                client.content &&
                client.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row?.accounts[0]?.name}</TableCell>
                    <TableCell>{row?.accounts[0]?.identification}</TableCell>
                    <TableCell>{row.personType}</TableCell>
                    <TableCell>
                      {formatDate(new Date(row.creationDate))}
                    </TableCell>
                    <TableCell>{row.stage.name}</TableCell>
                    <TableCell>{row.status.name}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Ver detalles" placement="top">
                        <IconButton
                          aria-label="sorter"
                          component="label"
                          data-id={row.id}
                          onClick={handleOpenModal}
                          data-name="details"
                        >
                          <DownIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {(!client || !client.content || client.content.length <= 0) && (
                <TableRow>
                  <TableCell colSpan={10}>No existen registros</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={client?.content?.length > 0 ? client?.content?.length : 0}
          rowsPerPage={client?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={client?.totalElements}
          totalPages={client?.totalPages}
        />
      </FancyPaper>
      <Dialog
        //@ts-ignore
        open={modalData?.modalOpen}
        onClose={handleCancelModal}
        fullWidth
        maxWidth="lg"
      >
        <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
          Histórico del cliente
        </Title>
        <DialogContent sx={{ px: 4, pb: 0 }}>
          <Divider sx={{ mb: 4 }} />
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Proceso</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Modificado por</TableCell>
                  <TableCell>Descripción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!customLoading &&
                  store &&
                  store.content &&
                  store.content.map((r: any, index: number) => (
                    <TableRow key={r.id}>
                      <TableCell component="th" scope="row">
                        {index}
                      </TableCell>
                      <TableCell>{r.process}</TableCell>
                      <TableCell>{formatDate(new Date(r.startDate))}</TableCell>
                      <TableCell>{r.user}</TableCell>
                      <TableCell>
                        {r.process === "uploadDocument" &&
                          r.response?.originalName}
                      </TableCell>
                    </TableRow>
                  ))}
                {(!store || !store.content || store.content.length <= 0) && (
                  <TableRow>
                    <TableCell colSpan={4}>No existen registros</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <FancyTablePagination
            count={store?.content?.length > 0 ? store?.content?.length : 0}
            rowsPerPage={store?.size}
            page={customPage}
            onPageChange={handleCustomChangePage}
            totalElements={store?.totalElements}
            totalPages={store?.totalPages}
          />
        </DialogContent>
        <Actions sx={{ mt: 4, pb: 4, px: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mt: 2,
              mr: 2,
            }}
            onClick={handleCancelModal}
            autoFocus
          >
            Cerrar
          </Button>{" "}
        </Actions>
      </Dialog>
    </>
  );
};

export default ClientManagement;
