export const data:any = {
  personType: ["Física", "Jurídica"],
  signatureType: ["Físico", "Digital"], 
  nacional_institucional: [
    "Administradora de Fondos de Pensiones (AFP)",
    "Administradora de Fondos de Inversiones (AFI)",
    "Puesto de Bolsa",
    "Fiduciaria",
    "Banco Múltiple",
    "Asociaciones de Ahorros y Prestamos"
  ],
  patrinomio_autonomos: [
    "Fondo de Pensiones",
    "Fondo de Inversión abierto/cerrado",
    "Fideicomiso"
  ],
  nacional_sin_fin_lucro: [
    "Cooperativa",
    "Organizaciones no gubernamentales"
  ],
  nacional_no_profesional: [
    "Sociedad anónima (SA)",
    "Sociedad anónima simplificada (SAS)",
    "Sociedad de responsabilidad limitada (SRL)",
    "Empresa individual de responsabilidad limitada (EIRL)"
  ],
  contrapartes: [
    "Contraparte local",
    "Contraparte extranjera",
    "Contraparte extranjera que cotiza en Bolsa"
  ],
  legalPerson:
    {
      "Personas nacionales institucionales":
        [
          "Administradora de Fondos de Pensiones (AFP)",
          "Administradora de Fondos de Inversiones (AFI)",
          "Puesto de Bolsa",
          "Fiduciaria",
          "Banco Múltiple",
          "Asociaciones de Ahorros y Prestamos"
        ],
      "Patrimonios autónomos (Institucionales)": 
        [
          "Fondo de Pensiones",
          "Fondo de Inversión abierto/cerrado",
          "Fideicomiso"
        ],
      "Nacionales sin fines de lucro": 
      [
        "Cooperativa",
        "Organizaciones no gubernamentales"
      ],
      "Nacionales profesionales": [],
      "Nacionales no profesionales": [
        "Sociedad anónima (SA)",
        "Sociedad anónima simplificada (SAS)",
        "Sociedad de responsabilidad limitada (SRL)",
        "Empresa individual de responsabilidad limitada (EIRL)"
      ],
      "Contrapartes": [
        "Contraparte local",
        "Contraparte extranjera",
        "Contraparte extranjera que cotiza en Bolsa"
      ]
    }  ,
  /* Nacional_Institucional:["",""],
  Autónomos_Institucionales:["",""],
  Nacionales_sin_fines_de_lucro:["",""],
  Nacionales_Profesionales:["",""],
  Nacionales_no_Profesionales:["",""],
  Contrapartes:["",""], */
  personTypeType: ["Nacionales Institucionales", "Autónomos Institucionales", "Nacionales sin fines de lucro",
  "Nacionales Profesionales", "Nacionales no Profesionales","Contrapartes"],
  risk: ["Nivel Bajo", "Nivel Medio", "Nivel Alto", " Nivel Alto (PEPS o Vinculado PEPS)"],
  fileForm: ["Físico", "Digital"], 
  typeAccount: ["Mancomunada", "Individual", "Parval Simple"]  ,
  userRole: [
    {id: 9, role_name: "Ejecutivo"}, 
    {id: 10, role_name:"Cumplimiento"}, 
    {id: 11, role_name:"Operaciones"},  
    {id: 12, role_name:"Servicio al Cliente"},  
    {id: 13, role_name:"Administrador"}  
  ],
  documents: [
    {id: 1, name: "Estado de cuenta"},
    {id: 2, name: "Historial laboral"},
    {id: 3, name: "Contrato corretaje digital"},
    {id: 4, name: "Formulario cononzca su cliente (I)"},
    {id: 5, name: "Contrato CEVALDOM (Anexo II)"},
    {id: 6, name: "Check list"},
    {id: 7, name: "Documentos de identidad"},
    {id: 8, name: "Verificación Biométrica"},
  ],
  ext: ["image/png", "image/jpeg", "image/jpg", ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".eml"]
}