import { FC } from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Stack,
  CircularProgress,
  Divider,
} from "@mui/material";
import TitleForm from "../../components/TitleForm";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { data as datos } from "../../data/data";

const BussinessIdentification: FC = ({
  data: dataCompleta,
  address,
  dataJ,
}: any) => {
  const formatDate = (date: any) => {
    const replace = date.replace(/^"(.*)"$/, "$1");
    const newDate = new Date(replace);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  const defaultValues: any = {
    investment_promoter: dataCompleta.investment_promoter
      ? dataJ &&
        dataJ.pa_ta_investment_promoter.find(
          (f: any) => f.id === Number(dataCompleta.investment_promoter)
        )
      : [],
    name_social_denomination: dataCompleta.name_social_denomination
      ? dataCompleta.name_social_denomination
      : "",
    company_email: dataCompleta.company_email ? dataCompleta.company_email : "",
    rnc_document_number: dataCompleta.rnc_document_number
      ? dataCompleta.rnc_document_number
      : "",
    business_registration_number: dataCompleta.business_registration_number
      ? dataCompleta.business_registration_number
      : "",
    relationship_with_parval: dataCompleta.relationship_with_parval
      ? dataCompleta.relationship_with_parval
      : "",
    company_type: dataCompleta.company_type
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_company_type.find(
          (f: any) => f.id === Number(dataCompleta.company_type)
        )
      : [],
    country_of_incorporation: dataCompleta.country_of_incorporation
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_countries.find(
          (f: any) => f.id === Number(dataCompleta.country_of_incorporation)
        )
      : [],
    incorporation_date: dataCompleta.incorporation_date
      ? formatDate(dataCompleta.incorporation_date)
      : null,
    website: dataCompleta.website ? dataCompleta.website : "",
    channel_catchment_client: dataCompleta.channel_catchment_client
      ? dataJ.pa_ta_channel_catchment_client.find(
          (f: any) => f.id === Number(dataCompleta.channel_catchment_client)
        )
      : [],
    personal_phone: dataCompleta.personal_phone
      ? dataCompleta.personal_phone
      : "",
    main_clients_and_countries: dataCompleta.main_clients_and_countries
      ? dataJ && //@ts-ignore
        dataJ.pa_ta_countries.find(
          (f: any) => f.id === Number(dataCompleta.main_clients_and_countries)
        )?.description
      : [],
    country: dataCompleta.country //@ts-ignore
      ? dataJ.pa_ta_countries.find(
          (f: any) => f.id === Number(dataCompleta.country)
        )
      : [],
    province: dataCompleta.province
      ? address &&
        address.provinces.find(
          (f: any) => f.id === Number(dataCompleta.province)
        )
      : [],
    sector: dataCompleta.sector
      ? address &&
        address.sector.find((f: any) => f.id === Number(dataCompleta.sector))
      : [],
    street_contact_person: dataCompleta.street_contact_person
      ? dataCompleta.street_contact_person
      : "",
    building: dataCompleta.building ? dataCompleta.building : "",

    legal_representative: (dataCompleta.legal_representative &&
      JSON.parse(dataCompleta.legal_representative)) ?? [
      {
        name: "",
        id_type: null,
        document_number: "",
        marital_status: "",
        date_of_birth: null,
        country_of_nationality: null,
        country: null,
        province: null,
        sector: null,
        address: "",
        building_name: "",
        floor_number: "",
        postal_code: "",
        email: "",
      },
    ],
    sector_type: dataCompleta.sector_type ?? "",
    business_activity: dataCompleta.business_activity
      ? dataCompleta.business_activity
      : "",
    quantity_of_employees: dataCompleta.quantity_of_employees
      ? dataCompleta.quantity_of_employees
      : "",
    number_of_shareholders: dataCompleta.number_of_shareholders
      ? dataCompleta.number_of_shareholders
      : "",
    annual_sales_volume: dataCompleta.annual_sales_volume
      ? dataCompleta.annual_sales_volume
      : "",
    products_or_services: dataCompleta.products_or_services
      ? dataCompleta.products_or_services
      : "",
  };

  const { register, control, watch } = useForm({
    defaultValues,
  });
  const { fields: legal_representativeFields } = useFieldArray({
    control,
    name: "legal_representative",
    rules: {
      minLength: 1,
    },
  });
  const watch_country: any = watch("country");
  const watch_legal_representative = watch("legal_representative");

  return (
    <form>
      <TitleForm>Información Personal</TitleForm>
      {!!!dataCompleta.rnc_document_number ? (
        <>
          <Stack spacing={2} direction="row" justifyContent="center">
            <CircularProgress color="inherit" />
          </Stack>
        </>
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="investment_promoter"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={datos.pa_ta_investment_promoter}
                    //options={data?.pa_ta_investment_promoter}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Representante de cuentas"
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"name_social_denomination"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre / Razón social"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="name_social_denomination"
                    {...register("name_social_denomination")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"rnc_document_number"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="RNC o equivalente"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="rnc_document_number"
                    {...register("rnc_document_number")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"business_registration_number"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de registro mercantil"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="business_registration_number"
                    {...register("business_registration_number")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="relationship_with_parval"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={datos.pa_ta_relationship_with_parval}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    value={value}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Relación con parval"
                        variant="standard"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="company_type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataJ.pa_ta_company_type}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "company_type";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de empresa"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="country_of_incorporation"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    // options={data && countryASC}
                    options={[]}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Lugar de constitución"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"incorporation_date"}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider
                    dateAdapter={DateFnsUtils}
                    adapterLocale={esLocale}
                  >
                    <DatePicker
                      value={field.value}
                      disabled={completeData}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      label="Fecha de constitución"
                      className={errors.incorporation_date && "Mui-error"}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid> */}
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"incorporation_date"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha de constitución"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="incorporation_date"
                    {...register("incorporation_date")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"company_email"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Email de la empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="company_email"
                    {...register("company_email")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"website"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Portal web de la empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="website"
                    {...register("website")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="channel_catchment_client"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={[]}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Canal de captación del cliente"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"personal_phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="personal_phone"
                    {...register("personal_phone")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
            Dirección oficina principal
          </TitleForm>
          <Grid container spacing={4}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="country"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    // options={data && countryASC}
                    options={[]}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="País"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            {watch_country?.id === 63 && (
              <>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="province"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        // options={address.provinces}
                        options={[]}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.provinceCode === value.provinceCode
                        }
                        value={value}
                        disabled
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Provincia"
                              variant="standard"
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="sector"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        // options={address.sector.filter(
                        //   (m: any) =>
                        //     m.provinceCode === watch_province?.provinceCode
                        // )}
                        // options={address.sector}
                        options={[]}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Sector"
                              variant="standard"
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"street_contact_person"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Calle / Número"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="street_contact_person"
                    {...register("street_contact_person")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"building"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Edificio / Torre"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="building"
                    {...register("building")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
            Información representante legal o apoderados
          </TitleForm>
          {legal_representativeFields.map((field, index) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 3,
                      borderColor: "#efe5df",
                      width: "50%",
                      ml: "auto",
                      mr: "auto",
                    }}
                  />
                )}
                <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.name`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombres y Apellidos"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="name"
                          {...register(`legal_representative.${index}.name`)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name={`legal_representative.${index}.id_type`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={datos.id_type}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Tipo de Identificación"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.document_number`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Número Documento"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="document_number"
                          {...register(
                            `legal_representative.${index}.document_number`
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name={`legal_representative.${index}.marital_status`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          options={datos.pa_ta_marital_status}
                          getOptionLabel={(option: any) => option || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option === value
                          }
                          value={value}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Estado civil"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name={`legal_representative.${index}.country_of_nationality`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          // options={data && countryASC}
                          options={[]}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="País de nacionalidad"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name={`legal_representative.${index}.country`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            onChange(newValue);
                          }}
                          // options={data && countryASC}
                          options={[]}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="País de Residencia"
                                variant="standard"
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {
                    //@ts-ignore
                    Number(watch_legal_representative[index].country?.id) ===
                      63 && (
                      <>
                        <Grid item md={4} sm={6} xs={12}>
                          <Controller
                            control={control}
                            name={`legal_representative.${index}.province`}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, newValue: any) => {
                                  onChange(newValue);
                                }}
                                // options={address.provinces}
                                options={[]}
                                getOptionLabel={(option: any) =>
                                  option.description || ""
                                }
                                isOptionEqualToValue={(
                                  option: any,
                                  value: any
                                ) => option.provinceCode === value.provinceCode}
                                value={value}
                                disabled
                                renderInput={(params) => {
                                  const inputProps = params.inputProps;
                                  inputProps.autoComplete = "new-password";
                                  return (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Provincia"
                                      variant="standard"
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                          <Controller
                            control={control}
                            name={`legal_representative.${index}.sector`}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Autocomplete
                                onChange={(event, newValue: any) => {
                                  onChange(newValue);
                                }}
                                // options={address.sector.filter(
                                //   (m: any) =>
                                //     m.provinceCode ===
                                //     watch_legal_representative[index]?.province
                                //       ?.provinceCode
                                // )}
                                // options={address.sector}
                                options={[]}
                                getOptionLabel={(option: any) =>
                                  option.description || ""
                                }
                                isOptionEqualToValue={(
                                  option: any,
                                  value: any
                                ) => option.id === value.id}
                                value={value}
                                disabled
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label="Sector"
                                    variant="standard"
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )
                  }
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.address`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Dirección (Calle /  Número)"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="address"
                          {...register(`legal_representative.${index}.address`)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.building_name`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombre Edificio / Torre"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="building_name"
                          {...register(
                            `legal_representative.${index}.building_name`
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.floor_number`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="N° Piso / Suite"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="floor_number"
                          {...register(
                            `legal_representative.${index}.floor_number`
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`legal_representative.${index}.postal_code`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Código postal"
                          type="text"
                          variant="standard"
                          disabled
                          autoComplete="postal_code"
                          {...register(
                            `legal_representative.${index}.postal_code`
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                    <Controller
                      name={`legal_representative.${index}.email`}
                      control={control}
                      render={() => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Correo electrónico"
                          type="email"
                          variant="standard"
                          disabled
                          autoComplete="email"
                          {...register(`legal_representative.${index}.email`)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
          <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
            Descripción del negocio
          </TitleForm>
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name="sector_type"
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Sector"
                    type="text"
                    variant="standard"
                    disabled
                    {...register("sector_type")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="business_activity"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={datos.pa_ta_business_activity}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password3";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Actividad del negocio"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"quantity_of_employees"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad de empleados"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="quantity_of_employees"
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"number_of_shareholders"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad de accionistas"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="number_of_shareholders"
                    {...register("number_of_shareholders")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="annual_sales_volume"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={datos.pa_ta_annual_sales_volume}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    value={value}
                    disabled
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password3";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Volumen de ventas anuales"
                          variant="standard"
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"products_or_services"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="	Productos y/o servicios"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="products_or_services"
                    {...register("products_or_services")}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"main_clients_and_countries"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Principales clientes y países"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled
                    autoComplete="main_clients_and_countries"
                    {...register("main_clients_and_countries")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
    </form>
  );
};

export default BussinessIdentification;
