import {URL} from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import Urls from "../../url/Urls";

export const getRequestBankEntity = async (page:number, filter:string) => {
  let responseData = {};      
  await privateFetch
    .get(
      filter === "" ? URL.REQUEST_BANKENTITY(
        page,
        Urls.registration_x_page
        ) : URL.REQUEST_BANKENTITY_SEARCH(filter)       
    )   
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const addRequestBankEntity  = async (data:any, option:string) => {
  let responseData = {}; 
  await privateFetch
    .post(URL.REQUEST_BANKENTITY_CUD(option), data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const UpdateRequestBankEntity  = async (data:any, option:string) => {
  let responseData = {}; 
  await privateFetch
    .put(URL.REQUEST_BANKENTITY_CUD(option), data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};


export const deleteRequestBankEntity = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_BANKENTITY_CUD(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log(error.message)
    });
  return responseData;
};
