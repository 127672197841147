import {
  Typography,
  Grid,
  FormControl,
  FormHelperText,
  Stack,
  Button,
} from "@mui/material";
import InputLabelToForm from "../components/InputLabelToForm";
import { useEffect, useState } from "react";
import { useApp } from "../hooks/useApp";
import { Chart } from "react-google-charts";
import {
  getRequestCustomerStage,
  getRequestCustomerPersonType,
  getRequestCustomerYear,
} from "../services/DashboardServices";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import FancyPaper from "../components/FancyPaper";
import { CONSTANT } from "../utils/Constants";

interface searchData {
  fromDate?: Date | null;
  toDate?: Date | null;
  year?: Date | null;
}

const defaultValues: searchData = {
  fromDate: new Date(new Date().getFullYear(), 0, 1),
  toDate: new Date(),
  year: new Date(),
};

const validation = Yup.object().shape({
  fromDate: Yup.date().typeError(""),
  toDate: Yup.date().typeError(""),
});

const Reports = () => {
  const { setLoading, setErrorMsg } = useApp();
  const [customerStage, setCustomerStage] = useState<any>(null);
  const [customerPersonType, setCustomerPersonType] = useState<any>(null);
  const [customerRegistered, setCustomerRegistered] = useState<any>(null);
  const { handleSubmit, formState, control } = useForm<searchData>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors } = formState;

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchCustomerStage(defaultValues);
      await handleFetchCustomerPersonType(defaultValues);
      await handleFetchCustomerRegistered(defaultValues);
    };
    dataInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchCustomerStage = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerStage = await getRequestCustomerStage(filter);
      if (dataCustomerStage) {
        setCustomerStage(dataCustomerStage);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };
  const handleFetchCustomerPersonType = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerPersonType = await getRequestCustomerPersonType(filter);
      if (dataCustomerPersonType) {
        setCustomerPersonType(dataCustomerPersonType);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };
  const handleFetchCustomerRegistered = async (filter: any) => {
    setLoading && setLoading(true);
    try {
      let dataCustomerRegistered = await getRequestCustomerYear(filter);
      if (dataCustomerRegistered) {
        setCustomerRegistered(dataCustomerRegistered);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case "customerStage":
        console.log("entra" + dataName);
        await handleFetchCustomerStage(data);
        break;
      case "customerPersonType":
        console.log("entra" + dataName);
        await handleFetchCustomerPersonType(data);
        break;
      case "customerRegistered":
        console.log("entra" + dataName);
        await handleFetchCustomerRegistered(data);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Reportes">
        <Typography
          variant="h3"
          component="h1"
          color="primary"
          align="center"
          mb={4}
        >
          ¡HOLA! BIENVENIDO A PARVAL
        </Typography>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtro de clientes por fase
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={"fromDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.fromDate && Boolean(errors.fromDate)}
                  >
                    Fecha desde
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.fromDate && (
                <FormHelperText>{errors.fromDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={"toDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.toDate && Boolean(errors.toDate)}
                  >
                    Fecha hasta
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.toDate && (
                <FormHelperText>{errors.toDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                name="customerStage"
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {customerStage !== null && (
              <Chart
                chartType="PieChart"
                data={customerStage}
                options={{ title: "Grafico porcentual de clientes por fase" }}
                width={"100%"}
                height={"400px"}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtro de clientes por tipo de persona
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={"fromDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.fromDate && Boolean(errors.fromDate)}
                  >
                    Fecha desde
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.fromDate && (
                <FormHelperText>{errors.fromDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={"toDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.toDate && Boolean(errors.toDate)}
                  >
                    Fecha hasta
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={
                      new Date(
                        "Thu Jan 01 2023 20:35:30 GMT-0400 (Venezuela Time)"
                      )
                    }
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT}
                  />
                </FormControl>
              )}
              {...(errors.toDate && (
                <FormHelperText>{errors.toDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                name="customerPersonType"
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {customerPersonType !== null && (
              <Chart
                chartType="PieChart"
                data={customerPersonType}
                options={{
                  title: "Grafico porcentual de  clientes por tipo de persona",
                }}
                width={"100%"}
                height={"400px"}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item xs={12}>
            <Typography
              sx={{ fontWeight: 700, mb: 2, fontSize: "1.06250rem" }}
              color="greyDue.dark"
            >
              Filtro de clientes registrados
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={"year"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.fromDate && Boolean(errors.fromDate)}
                  >
                    Seleccione año
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 20)
                      )
                    }
                    maxDate={new Date()}
                    showYearPicker
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    dateFormat="yyyy"
                  />
                </FormControl>
              )}
              {...(errors.fromDate && (
                <FormHelperText>{errors.fromDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                name="customerRegistered"
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {customerRegistered !== null && (
              <Chart
                chartType="PieChart"
                data={customerRegistered}
                options={{
                  title: "Grafico porcentual de  clientes registrados",
                }}
                width={"100%"}
                height={"400px"}
              />
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default Reports;
