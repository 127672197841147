import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  Select,
  InputLabel,
  styled,
  FormControl,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { itemNotary, itemBranch } from "../../types/investmentMaintenanceType";

interface DialogProps {
  actionButton?: any;
  data: any;
  dataNotary: any;
  onSubmit: any;
  cancelModal: any;
  modalType: any;
}

const AddOrUpdateBranchOffice = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  dataNotary,
}: DialogProps) => {
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    address: Yup.string().required("Campo es requerido"),
    notaryId: Yup.string().required("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    address: data.address ? data.address : "",
    notaryId: data.notaryId ? data.notaryId : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<itemBranch>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {modalType === "create" ? "Agregar sucursal" : "Editar sucursal"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    value={value}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"address"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Dirección"
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    value={value}
                    {...register("address")}
                    error={errors.address && Boolean(errors.address)}
                    helperText={errors.address && errors.address.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"notaryId"}
                control={control}
                render={({ field: { value } }) => (
                  <FormControl fullWidth>
                    <InputLabel sx={{ marginTop: "7px", marginLeft: "-14px" }}>
                      Notario
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={value}
                      {...register("notaryId")}
                    >
                      {dataNotary?.map((n: itemNotary) => (
                        <MenuItem value={n.id} key={n.id}>
                          {n.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateBranchOffice;
