import {
  Button,
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  MenuItem,
  Box,
  Chip,
  ListItemText,
  Select,
  FormControl,
  FormLabel,
  useTheme,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { data as datos } from "../../resources/data/data";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateEmailRole {
  id?: number;
  document?: {
    id?: number;
    name?: string;
  }[];
  role: {
    id?: number;
    name?: string;
  }[];
  personType: string;
  risk: string;
  signatureType: string;
  requiredJuridic: boolean;
  requiredPhysical: boolean;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
  documentListData?: any;
}

const AddOrUpdateDocumentRole = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  documentListData,
}: DialogProps) => {
  const theme = useTheme();
  const validation = Yup.object().shape({
    document: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    role: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    personType: Yup.array(),
    risk: Yup.array(),
    requiredJuridic: Yup.boolean(),
    requiredPhysical: Yup.boolean(),
    signatureType: Yup.array(),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    document: data.document
      ? documentListData.content.find((d: any) => d.id === data.document.id)
      : [],
    role: data.role ? roleData.find((f: any) => f.id === data.role.id) : [],
    //required: data.required ? data.required : false,
    requiredJuridic: data.requiredJuridic ? data.requiredJuridic : false,
    requiredPhysical: data.requiredPhysical ? data.requiredPhysical : false,
    personType: data.personType ? JSON.parse(data.personType) : [],
    risk: data.risk ? JSON.parse(data.risk) : [],
    signatureType: data.signatureType ? JSON.parse(data.signatureType) : [],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateEmailRole>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create"
          ? "Agregar documento por perfil"
          : "Actualizar documento por perfil"}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="document"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={documentListData.content}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Documento"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.document}
                          helperText={
                            errors.document && errors.document.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="role"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={roleData}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.role}
                          helperText={errors.role && errors.role.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="personType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Tipo de persona
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("personType")}
                      sx={{
                        "& .MuiSelect-select": { paddingLeft: "0!important" },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected?.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.personType.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.personType.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="signatureType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Tipo de firma
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("signatureType")}
                      sx={{
                        "& .MuiSelect-select": { paddingLeft: "0!important" },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected?.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.signatureType.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.signatureType.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Controller
                control={control}
                name="risk"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel sx={{ color: theme.palette.text.primary }}>
                      Nivel de riesgo
                    </FormLabel>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("risk")}
                      sx={{
                        "& .MuiSelect-select": { paddingLeft: "0!important" },
                      }}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={datos.risk.find(
                                (item: any) => item === value
                              )}
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.risk.map((item: any) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox checked={value?.indexOf(item) > -1} />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="requiredPhysical"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Requerido persona fisica"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="requiredJuridic"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Requerido persona juridica"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateDocumentRole;
