import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import Urls from "../../url/Urls";

export const getRequestBlackList = async (page: number, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["alias", filter.alias ?? ""],
    ["document-number", filter.document ?? ""],
    ["name", filter.name ?? ""],
    ["source", filter.source ?? ""],
    ["surname", filter.surname ?? ""],
    [
      "is-client",
      filter?.isClient !== "" ? (filter.isClient === "1" ? true : false) : "",
    ],
  ]);
  let keysParams: any = [];
  params.forEach((value, key) => {
    if (value === "" || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key: any) => {
    params.delete(key);
  });
  await privateFetch
    .get(URL.REQUEST_BLACK_LIST(page, Urls.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};
export const getRequestBlackListID = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_BLACK_LIST_ID(id))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createRequestBlackList = async (data: any) => {
  let responseData = {};

  await privateFetch
    .post(URL.REQUEST_BLACK_LIST_ID(), data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const updateRequestBlackList = async (data: any) => {
  console.log(data);
  let responseData = {};

  await privateFetch
    .put(URL.REQUEST_BLACK_LIST_ID(data.id), data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const deleteRequestBlackListID = async (id: any, user?: string) => {
  let responseData: any = {};
  await privateFetch
    .delete(URL.REQUEST_BLACK_LIST_ID(id, user))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return {
    body: responseData,
    status: responseData.status,
  };
};
