import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch.js";
import { CONSTANT } from "../utils/Constants";
import { formatDate } from "../utils/UtilsFunctions";

export const getRequestCustomerStage = async (filter:any ) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["fromDate", formatDate(filter.fromDate)],
    ["toDate", formatDate(filter.toDate)]
  ]); 
 
  await privateFetch 
    .get(URL.REQUEST_CUSTOMER_STAGE, { params })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestCustomerPersonType = async (filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["fromDate", formatDate(filter.fromDate)],
    ["toDate", formatDate(filter.toDate)]
  ]); 
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_PERSON_TYPE, { params })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestCustomerRegistered = async (filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["fromDate", formatDate(filter.fromDate)],
    ["toDate", formatDate(filter.toDate)]
  ]); 
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_REGISTERED, { params })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestCustomerLeads = async (filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["fromDate", formatDate(filter.fromDate)],
    ["toDate", formatDate(filter.toDate)]
  ]); 
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_LEADS, { params })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestCustomerYear = async (filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["year", filter.year.getFullYear() ],
  ]); 
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_YEAR, { params })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};


export const getRequestCustomerComparation = async () => {
  let responseData = {}; 
  await privateFetch
    .get(URL.REQUEST_CUSTOMER_COMPARATION, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      }
    })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};



