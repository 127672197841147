import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";

export const getAllInvestmentProfiles = async (page:  number, size: number, filter: string) => {
  let responseData = {};  
  await privateFetch
    .get(
      filter !== "" 
      ? URL.REQUEST_LIST_INVESTMENT_FILTER(page, size, filter) 
      : URL.REQUEST_LIST_INVESTMENT(page, size)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const getCalculate = async (id: number) => {
  let responseData = {};
  await privateFetch 
    .get(
      URL.REQUEST_INVESTMENT_PROFILE_CALCULATE(id)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getInvestmentProfileById = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_INVESTMENT_PROFILE_WITH_ID(id)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
}

export const getAddressData = async () => {
  try {
    const getApiP = await privateFetch('/perfil-maintenance/provinces/?size=100');
    const getApiS = await privateFetch('/perfil-maintenance/sectors/?size=600');

    return {
      provinces: getApiP.data.content,
      sector: getApiS.data.content,
    };
  } catch (error) {
    console.log(error);
    return { body: error };
  }
};

export const getAllDataJuridic = async () => {
  try {
    const getApi = await privateFetch('/perfil-maintenance/allData/?type=J');
    return getApi.data;
  } catch (error) {
    console.log(error);
    return { body: error };
  }
}