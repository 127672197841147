import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const Gradient = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  borderWidth: "1px",
  borderStyle: "solid",
  boxShadow: "none!important",
  borderRadius: theme.spacing(4),
  padding: `${theme.spacing(0.5)} ${theme.spacing(5)}`,
  "&:hover": {
    backgroundColor: "#ffffff",
    color: theme.palette.secondary.dark,
    borderColor: theme.palette.primary.main,
  },
}));

const ButtonGradient = ({ type, children, ...props }: any) => {
  return (
    <Gradient type={type} variant="contained" {...props}>
      {children}
    </Gradient>
  );
};

export default ButtonGradient;
